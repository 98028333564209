export const tarifsDieselCatPersoSUNU = [
  {
    libelle: '1',
    prix: 58675
  },
  {
    libelle: '2 à 4',
    prix: 66885
  },
  {
    libelle: '5 à 6',
    prix: 73415
  },
  {
    libelle: '7 à 8',
    prix: 114693
  },
  {
    libelle: '9 et plus',
    prix: 129058
  }
];
