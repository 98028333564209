import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import {Router} from '@angular/router';

import { environment } from '../../environments/environment';
import { User } from '../_models/user';
import { stringify } from '@angular/compiler/src/util';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  env = environment;

  constructor(private httpClient: HttpClient, private router: Router) { }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('Une erreur est survenue:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend a retourné le code ${error.status}, ` +
        `Pour resultat: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Erreur lors de la requete');
  }
  getUserInfosByNumber(phone: string) {

    return this.httpClient.get<any[]>(this.env.domaineUserbyPhone + phone)
    .pipe(
      tap(res => { console.log(res); })
    );
  }
  getRegisterOnApi(utilisateur: User) {
    
    return this.httpClient.post<any[]>(this.env.domaineAPI, utilisateur, this.env.httpOptions)
    .pipe(tap(
      res => {
        //localStorage.setItem('access_token', res.user.name);
      }),
      catchError(this.handleError)
    )
  }
  getAuthOnApi(email: string, password: string) {

    return this.httpClient.post<any[]>(this.env.domaineAuth, {email, password}, this.env.httpOptions)
    .pipe(tap(
      res => {
        //localStorage.setItem('access_token', res.user.token);
      }),
      catchError(this.handleError)
    );
  }
  isUserAuth(): boolean {

    if (sessionStorage.getItem('isAuth') === undefined || sessionStorage.getItem('isAuth') === '') {
        return false;
    } else {

      switch (sessionStorage.getItem('isAuth')) {
        case 'false':
          return false;
        case 'true':
          return true;
      }
    }
  }
  convertBooleanToString(value: boolean): string {

    switch (value) {
      case false:
        return 'false';
      case true:
        return 'true';
    }
  }
  convertStringToBoolean(value: string): boolean {

    switch (value) {
      case 'false':
        return false;
      case 'true':
        return true;
    }
  }
  translateBoolean(value: boolean): string {
    switch(value) {
      case true:
        return 'OUI';
      case false:
        return 'NON';
    }
  }

  getUserAllInfos(): {  fullName: string; firstName: string; lastName: string; email: string; birthday: string; created_at: string;
    updated_at: string; phone: string; has_payqin_account: boolean; customerNumber: string; shopId: string } {

    return {
      fullName: sessionStorage.getItem('firstname') + ' ' + sessionStorage.getItem('lastname'),
      firstName: sessionStorage.getItem('firstname'),
      lastName: sessionStorage.getItem('lastname'),
      email: sessionStorage.getItem('email'),
      birthday: sessionStorage.getItem('birthday'),
      created_at: sessionStorage.getItem('created_at'),
      updated_at: sessionStorage.getItem('updated_at'),
      phone: sessionStorage.getItem('phone'),
      has_payqin_account: this.convertStringToBoolean(sessionStorage.getItem('has_payqin_account')),
      customerNumber: sessionStorage.getItem('customerNumber'),
      shopId: sessionStorage.getItem('shopId'),
    };
  }
  // tslint:disable-next-line:variable-name
  changeUserPassword(email: string, password: string, new_password: string) {

    return this.httpClient.post<any[]>(this.env.domaineUpdateMdp, { email, password, new_password }, this.env.httpOptions)
    .pipe(tap(
      res => {
        //localStorage.setItem('access_token', res.user.token);
      }),
      catchError(this.handleError)
    );
  }
  updateUserProfil(firstname: string, lastname: string, email: string, phone: string, birthday: string) {

    return this.httpClient.post<any[]>(this.env.domaineUpdateProfile, { firstname, lastname, email, phone, birthday }, this.env.httpOptions)
    .pipe(tap(
      res => {
        // localStorage.setItem('access_token', res.user.token);
      }),
      catchError(this.handleError)
    );
  }
  getCreateTransaction(shopId: string, vendorId: string, amount: number, customerNumber: string, orderNumber: string, signature: string) {

    // tslint:disable-next-line:max-line-length
    return this.httpClient.post<any[]>(this.env.domaineTransaction, { shopId, vendorId, amount, customerNumber, orderNumber, signature })
    .pipe(tap(
      res => {
        // localStorage.setItem('access_token', res.user.token);
      }),
      catchError(this.handleError)
    );

  }


  deconnexion() {
    sessionStorage.removeItem('isAuth');
    this.env.isAuth = false;
    sessionStorage.clear();
  }

}
/*function next(next: any, arg1: (user: any) => void): import("rxjs").OperatorFunction<any[], any[]> {
  throw new Error('Function not implemented.');
}*/

