import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { TermesEtConditionsComponent } from './pages/termes-et-conditions/termes-et-conditions.component';
import { AutomobileComponent } from './pages/automobile/automobile.component';
import { VoyageComponent } from './pages/voyage/voyage.component';
import { SanteComponent } from './pages/sante/sante.component';
import { HabitationComponent } from './pages/habitation/habitation.component';
import { AboutComponent } from './pages/about/about.component';
import { CommentAcheterMonAssuranceComponent } from './pages/comment-acheter-mon-assurance/comment-acheter-mon-assurance.component';
// tslint:disable-next-line:max-line-length
import { RenouvelerModifierMonAssuranceComponent } from './pages/renouveler-modifier-mon-assurance/renouveler-modifier-mon-assurance.component';
// tslint:disable-next-line:max-line-length
import { ComprendreMonContratAssuranceComponent } from './pages/comprendre-mon-contrat-assurance/comprendre-mon-contrat-assurance.component';
import { ContactComponent } from './pages/contact/contact.component';
import { LoginComponent } from './pages/login/login.component';
import { Page404Component } from './pages/page404/page404.component';
import { AccountComponent } from './pages/account/account.component';
import { RegisterComponent } from './pages/register/register.component';
import { InscriptionComponent } from './pages/inscription/inscription.component';
import { ConnexionComponent } from './pages/connexion/connexion.component';

import { AuthGuardGuard } from './_guard/auth-guard.guard';
import { TestComponent } from './test/test.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';

const routes: Routes = [

  // { path: '', redirectTo: 'accueil', pathMatch: 'full' },
  { path: '', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent},
  { path: 'inscription', component: InscriptionComponent},
  { path: 'connexion', component: ConnexionComponent},
  { path: 'test', component: TestComponent},
  { path: 'assurance/sante', component: SanteComponent },
  { path: 'assurance/voyage', component: VoyageComponent },
  { path: 'assurance/auto', component: AutomobileComponent },
  { path: 'assurance/habitation', component: HabitationComponent },
  { path: 'aide/qui-sommes-nous', component: AboutComponent },
  { path: 'aide/comment-acheter-mon-assurance', component: CommentAcheterMonAssuranceComponent },
  { path: 'aide/renouveler-ou-modifier-mon-assurance', component: RenouvelerModifierMonAssuranceComponent },
  { path: 'aide/comprendre-mon-assurance', component: ComprendreMonContratAssuranceComponent },
  { path:  'user/account', canActivate:[AuthGuardGuard], component:  AccountComponent},
  { path: 'contact', component: ContactComponent },
  { path: 'termes-et-conditions', component: TermesEtConditionsComponent },
  { path: 'notification', component: NotificationsComponent },
  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
