export const tabChargeUtileALLIANZ = [
  {
    libelle: 'jusqu\'a 1T',
    prix: 118965
  },
  {
    libelle: '1.01T à 3T',
    prix: 155190
  },
  {
    libelle: '3.01T à 5T',
    prix: 189715
  },
  {
    libelle: '5.01T à 8T',
    prix: 244617
  },
  {
    libelle: '8.01T à 11T',
    prix: 260925
  },
  {
    libelle: '11.01T à 13T',
    prix: 273407
  },
  {
    libelle: '13.01T à 15T',
    prix: 329405
  },
  {
    libelle: 'plus de 15T',
    prix: 431327
  },
  {
    libelle: 'tracteur routier',
    prix: 463925
  }
];
