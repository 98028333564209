import { Injectable } from '@angular/core';

import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  constructor(private ngxService: NgxUiLoaderService) { }

  chargement(){

    this.ngxService.start(); // start foreground loading with 'default' id
    // Stop the foreground loading after 3s
    setTimeout(() => {
      this.ngxService.stop(); // stop foreground loading with 'default' id
    }, 1000);
    // OR
    this.ngxService.startBackground('do-background-things');
    // Do something here...
    this.ngxService.stopBackground('do-background-things');
  }

}
