import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { LoadingService } from '../../_services/loading.service';
import { TarifCategorie01Service } from '../../_services/tarif-categorie01.service';
import { detailsCouverture } from '../../_shared/auto/monttDesCouvertures';
import { environment } from '../../../environments/environment';

// COUTS TARIFAIRE ASSURANCE SAHAM
import { tarifsEssenceCatPersoSAHAM } from '../../_shared/auto/assurance/Saham/tarifsEssenceCatPersoSAHAM';
import { tarifsDieselCatPersoSAHAM } from '../../_shared/auto/assurance/Saham/tarifsDieselCatPersoSAHAM';
import { tabChargeUtileSAHAM } from '../../_shared/auto/assurance/Saham/chargeUtileSAHAM';
import { tarifsDieselCatSocieteSAHAM } from '../../_shared/auto/assurance/Saham/tarifsDieselCatSocieteSAHAM';
import { tarifsEssenceCatSocieteSAHAM } from '../../_shared/auto/assurance/Saham/tarifsEssenceCatSocieteSAHAM';
import { ConstGarantiesSAHAM } from '../../_shared/auto/assurance/Saham/ConstGarantiesSAHAM';

// COUTS TARIFAIRE ASSURANCE SUNU
import { tarifsEssenceCatPersoSUNU } from '../../_shared/auto/assurance/Sunu/tarifsEssenceCatPersoSUNU';
import { tarifsDieselCatPersoSUNU } from '../../_shared/auto/assurance/Sunu/tarifsDieselCatPersoSUNU';
import { tabChargeUtileSUNU } from '../../_shared/auto/assurance/sunu/ChargeUtileSUNU';
import { tarifsDieselCatSocieteSUNU } from '../../_shared/auto/assurance/Sunu/tarifsDieselCatSocieteSUNU';
import { tarifsEssenceCatSocieteSUNU } from '../../_shared/auto/assurance/Sunu/tarifsEssenceCatSocieteSUNU';
import { ConstGarantiesSUNU } from '../../_shared/auto/assurance/Sunu/ConstGarantiesSUNU';

// COUTS TARIFAIRE ASSURANCE ALLIANZ
import { tabChargeUtileALLIANZ } from '../../_shared/auto/assurance/Allianz/chargeUtileALLIANZ';
import { ConstGarantiesALLIANZ } from '../../_shared/auto/assurance/Allianz/ConstGarantiesALLIANZ';
import { tarifsDieselCatPersoALLIANZ } from '../../_shared/auto/assurance/Allianz/tarifsDieselCatPersoALLIANZ';
import { tarifsDieselCatSocieteALLIANZ } from '../../_shared/auto/assurance/Allianz/tarifsDieselCatSocieteALLIANZ';
import { tarifsEssenceCatPersoALLIANZ } from '../../_shared/auto/assurance/Allianz/tarifsEssenceCatPersoALLIANZ';
import { tarifsEssenceCatSocieteALLIANZ } from '../../_shared/auto/assurance/Allianz/tarifsEssenceCatSocieteALLIANZ';

// COUTS TARIFAIRE ASSURANCE SIDAM
import { tabChargeUtileSIDAM } from '../../_shared/auto/assurance/Sidam/ChargeUtileSIDAM';
import { ConstGarantiesSIDAM } from '../../_shared/auto/assurance/Sidam/ConstGarantiesSIDAM';
import { tarifsDieselCatPersoSIDAM } from '../../_shared/auto/assurance/Sidam/tarifsDieselCatPersoSIDAM';
import { tarifsDieselCatSocieteSIDAM } from '../../_shared/auto/assurance/Sidam/tarifsDieselCatSocieteSIDAM';
import { tarifsEssenceCatPersoSIDAM } from '../../_shared/auto/assurance/Sidam/tarifsEssenceCatPersoSIDAM';
import { tarifsEssenceCatSocieteSIDAM } from '../../_shared/auto/assurance/Sidam/tarifsEssenceCatSocieteSIDAM';

// COTISATION POURCENTAGE SUR LA DUREE DE LASSURANCE
import { ConstCotisation } from '../../_shared/auto/ConstCotisations';

// Taux de dépréciatrion applicable aux véhicules terrestre à moteur
import { Taux } from "../../_shared/auto/tauxDepreciation";
import { MESTAUX } from "../../_shared/auto/contTaux";

// INTERFACES
import { PuissanceTarifs } from '../../_shared/auto/models/puissance-tarifs';
import { ChargeUtileTarifs } from '../../_shared/auto/models/puissance-tarifs';
import { parse } from 'querystring';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-automobile',
  templateUrl: './automobile.component.html',
  styleUrls: ['./automobile.component.scss']
})

export class AutomobileComponent implements OnInit {

  env = environment;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  firthFormGroup: FormGroup;
  isSubmitted = false;
  isSubmitted02 = false;
  isSubmitted03 = false;
  isSubmitted04 = false;
  fileData: File = null;
  msgErrorScdForm = true;
  showModalVehPerso = true;
  showModalVehUtil = true;
  showModalVehSoc = true;
  titleModalCategory = 'Pour plus de détails sur cette catégorie';
  isEssence = true;

  // Autres details du choix utilisateur
  pourcentagCatSocio = 0;
  dureeSelect = 0;
  pourcentagDuree = 0;
  valNeuve = 0;

  // DETAILS DES VALEURS DES ASSURANCES
  detailsMttCouvSimpleSAHAM = detailsCouverture;
  detailsMttCouvSimpleSUNU: any;
  detailsMttCouvSimpleALLIANZ: any;
  detailsMttCouvSimpleSIDAM: any;

  // Autre montant de l'assurance
  mttAccessoire = 5000;
  mttCEDEAO = 1000;

  formRealSelectSituation: string;

  constructor(private formBuilder: FormBuilder, private loadingService: LoadingService, private http: HttpClient,
              private tarifCategorie01Service: TarifCategorie01Service) { }

  // convenience getter for easy access to form fields
  get f() { return this.firstFormGroup.controls; }
  get t() { return this.thirdFormGroup.controls; }
  get m() { return this.fourthFormGroup.controls; }
  get g() { return this.firthFormGroup.controls; }

  // convenience getter for easy access to form fields values
  get ss() { return this.secondFormGroup.value; }


  onFileChanged(event: any) {
    const file = event.target.files[0];
    console.log(file);
  }
  fileProgress(fileInput: any) {
    this.fileData = fileInput.target.files[0] as File;
  }
  validationFirstForm() {

    this.loadingService.chargement();
    this.isSubmitted = true;
    if (this.firstFormGroup.invalid)  {
      return;
    }
      else {
       console.log('le formulaire 1 a ete valide');
       console.log( this.firstFormGroup );
     }
  }
  validationSecondForm() {

    this.loadingService.chargement();
    if (this.secondFormGroup.invalid)  {
      this.msgErrorScdForm = false;
      return;
    } else {

      this.msgErrorScdForm = true;

    }
  }
  validationFirthForm() {

    this.loadingService.chargement();
    if (this.firthFormGroup.invalid)  {
      return;
    } else {

      switch (this.secondFormGroup.value.choice) {

        case 'simple':
          this.varReinitialiser( this.detailsMttCouvSimpleSAHAM );
          // CALCUL POUR SAHAM
          // tslint:disable-next-line:max-line-length
          this.determineCouvSimpleMonttAPayer(this.detailsMttCouvSimpleSAHAM, this.fourthFormGroup.value.puissance, this.fourthFormGroup.value.energie, this.firstFormGroup.value.situation, tarifsEssenceCatPersoSAHAM, tarifsDieselCatPersoSAHAM, tarifsEssenceCatSocieteSAHAM, tarifsDieselCatSocieteSAHAM, this.thirdFormGroup.value.categorie, tabChargeUtileSAHAM, this.fourthFormGroup.value.chargeUtile, ConstGarantiesSAHAM);
          this.detailsMttCouvSimpleSAHAM.prixConvertDureeTotalAPayer = (this.detailsMttCouvSimpleSAHAM.totalApayer * this.pourcentagDuree);

          // CALCUL POUR SUNU
          // tslint:disable-next-line:max-line-length
          this.determineCouvSimpleMonttAPayer(this.detailsMttCouvSimpleSUNU, this.fourthFormGroup.value.puissance, this.fourthFormGroup.value.energie, this.firstFormGroup.value.situation, tarifsEssenceCatPersoSUNU, tarifsDieselCatPersoSUNU, tarifsEssenceCatSocieteSUNU, tarifsDieselCatSocieteSUNU, this.thirdFormGroup.value.categorie, tabChargeUtileSUNU, this.fourthFormGroup.value.chargeUtile, ConstGarantiesSUNU);
          this.detailsMttCouvSimpleSUNU.prixConvertDureeTotalAPayer = (this.detailsMttCouvSimpleSUNU.totalApayer * this.pourcentagDuree);

          // CALCUL POUR ALLIANZ
          // tslint:disable-next-line:max-line-length
          this.determineCouvSimpleMonttAPayer(this.detailsMttCouvSimpleALLIANZ, this.fourthFormGroup.value.puissance, this.fourthFormGroup.value.energie, this.firstFormGroup.value.situation, tarifsEssenceCatPersoALLIANZ, tarifsDieselCatPersoALLIANZ, tarifsEssenceCatSocieteALLIANZ, tarifsDieselCatSocieteALLIANZ, this.thirdFormGroup.value.categorie, tabChargeUtileALLIANZ, this.fourthFormGroup.value.chargeUtile, ConstGarantiesALLIANZ);
          // tslint:disable-next-line:max-line-length
          this.detailsMttCouvSimpleALLIANZ.prixConvertDureeTotalAPayer = (this.detailsMttCouvSimpleALLIANZ.totalApayer * this.pourcentagDuree);

          // CALCUL POUR SIDAM
          // tslint:disable-next-line:max-line-length
          this.determineCouvSimpleMonttAPayer(this.detailsMttCouvSimpleSIDAM, this.fourthFormGroup.value.puissance, this.fourthFormGroup.value.energie, this.firstFormGroup.value.situation, tarifsEssenceCatPersoSIDAM, tarifsDieselCatPersoSIDAM, tarifsEssenceCatSocieteSIDAM, tarifsDieselCatSocieteSIDAM, this.thirdFormGroup.value.categorie, tabChargeUtileSIDAM, this.fourthFormGroup.value.chargeUtile, ConstGarantiesSIDAM);
          // tslint:disable-next-line:max-line-length
          this.detailsMttCouvSimpleSIDAM.prixConvertDureeTotalAPayer = (this.detailsMttCouvSimpleSIDAM.totalApayer * this.pourcentagDuree);

          break;

        case 'ameliore':
          this.varReinitialiser( this.detailsMttCouvSimpleSAHAM );
          this.varReinitialiser( this.detailsMttCouvSimpleSUNU );

          // CALCUL POUR SAHAM
          // tslint:disable-next-line:max-line-length
          this.determineCouvAmelioreMonttAPayer(this.detailsMttCouvSimpleSAHAM, this.fourthFormGroup.value.puissance, this.fourthFormGroup.value.energie, this.firstFormGroup.value.situation, this.fourthFormGroup.value.valVenale, this.fourthFormGroup.value.valNoeuve, tarifsEssenceCatPersoSAHAM, tarifsDieselCatPersoSAHAM, tarifsEssenceCatSocieteSAHAM, tarifsDieselCatSocieteSAHAM, this.thirdFormGroup.value.categorie, tabChargeUtileSAHAM, this.fourthFormGroup.value.chargeUtile, ConstGarantiesSAHAM);

          // CALCUL POUR SUNU
          // tslint:disable-next-line:max-line-length
          this.determineCouvAmelioreMonttAPayer(this.detailsMttCouvSimpleSUNU, this.fourthFormGroup.value.puissance, this.fourthFormGroup.value.energie, this.firstFormGroup.value.situation, this.fourthFormGroup.value.valVenale, this.fourthFormGroup.value.valNoeuve, tarifsEssenceCatPersoSUNU, tarifsDieselCatPersoSUNU, tarifsEssenceCatSocieteSUNU, tarifsDieselCatSocieteSUNU, this.thirdFormGroup.value.categorie, tabChargeUtileSUNU, this.fourthFormGroup.value.chargeUtile, ConstGarantiesSUNU);

          // CALCUL POUR ALLIANZ
          // tslint:disable-next-line:max-line-length
          this.determineCouvAmelioreMonttAPayer(this.detailsMttCouvSimpleALLIANZ, this.fourthFormGroup.value.puissance, this.fourthFormGroup.value.energie, this.firstFormGroup.value.situation, this.fourthFormGroup.value.valVenale, this.fourthFormGroup.value.valNoeuve, tarifsEssenceCatPersoALLIANZ, tarifsDieselCatPersoALLIANZ, tarifsEssenceCatSocieteALLIANZ, tarifsDieselCatSocieteALLIANZ, this.thirdFormGroup.value.categorie, tabChargeUtileALLIANZ, this.fourthFormGroup.value.chargeUtile, ConstGarantiesALLIANZ);

          // CALCUL POUR SIDAM
          // tslint:disable-next-line:max-line-length
          this.determineCouvAmelioreMonttAPayer(this.detailsMttCouvSimpleSIDAM, this.fourthFormGroup.value.puissance, this.fourthFormGroup.value.energie, this.firstFormGroup.value.situation, this.fourthFormGroup.value.valVenale, this.fourthFormGroup.value.valNoeuve, tarifsEssenceCatPersoSIDAM, tarifsDieselCatPersoSIDAM, tarifsEssenceCatSocieteSIDAM, tarifsDieselCatSocieteSIDAM, this.thirdFormGroup.value.categorie, tabChargeUtileSIDAM, this.fourthFormGroup.value.chargeUtile, ConstGarantiesSIDAM);

          break;

        case 'prevision':
          this.varReinitialiser( this.detailsMttCouvSimpleSAHAM );
          this.varReinitialiser( this.detailsMttCouvSimpleSUNU );

          // CALCUL POUR SAHAM
          // tslint:disable-next-line:max-line-length
          this.determineCouvCollisionMonttAPayer(this.detailsMttCouvSimpleSAHAM, this.fourthFormGroup.value.puissance, this.fourthFormGroup.value.energie, this.firstFormGroup.value.situation, this.fourthFormGroup.value.valVenale, this.fourthFormGroup.value.valNoeuve, tarifsEssenceCatPersoSAHAM, tarifsDieselCatPersoSAHAM, tarifsEssenceCatSocieteSAHAM, tarifsDieselCatSocieteSAHAM, this.thirdFormGroup.value.categorie, tabChargeUtileSAHAM, this.fourthFormGroup.value.chargeUtile, ConstGarantiesSAHAM);

          // CALCUL POUR SUNU
          // tslint:disable-next-line:max-line-length
          this.determineCouvCollisionMonttAPayer(this.detailsMttCouvSimpleSUNU, this.fourthFormGroup.value.puissance, this.fourthFormGroup.value.energie, this.firstFormGroup.value.situation, this.fourthFormGroup.value.valVenale, this.fourthFormGroup.value.valNoeuve, tarifsEssenceCatPersoSUNU, tarifsDieselCatPersoSUNU, tarifsEssenceCatSocieteSUNU, tarifsDieselCatSocieteSUNU, this.thirdFormGroup.value.categorie, tabChargeUtileSUNU, this.fourthFormGroup.value.chargeUtile, ConstGarantiesSUNU);

          // CALCUL POUR ALLIANZ
          // tslint:disable-next-line:max-line-length
          this.determineCouvCollisionMonttAPayer(this.detailsMttCouvSimpleALLIANZ, this.fourthFormGroup.value.puissance, this.fourthFormGroup.value.energie, this.firstFormGroup.value.situation, this.fourthFormGroup.value.valVenale, this.fourthFormGroup.value.valNoeuve, tarifsEssenceCatPersoALLIANZ, tarifsDieselCatPersoALLIANZ, tarifsEssenceCatSocieteALLIANZ, tarifsDieselCatSocieteALLIANZ, this.thirdFormGroup.value.categorie, tabChargeUtileALLIANZ, this.fourthFormGroup.value.chargeUtile, ConstGarantiesALLIANZ);

          // CALCUL POUR SIDAM
          // tslint:disable-next-line:max-line-length
          this.determineCouvCollisionMonttAPayer(this.detailsMttCouvSimpleSIDAM, this.fourthFormGroup.value.puissance, this.fourthFormGroup.value.energie, this.firstFormGroup.value.situation, this.fourthFormGroup.value.valVenale, this.fourthFormGroup.value.valNoeuve, tarifsEssenceCatPersoSIDAM, tarifsDieselCatPersoSIDAM, tarifsEssenceCatSocieteSIDAM, tarifsDieselCatSocieteSIDAM, this.thirdFormGroup.value.categorie, tabChargeUtileSIDAM, this.fourthFormGroup.value.chargeUtile, ConstGarantiesSIDAM);

          break;

        case 'tout':
          this.varReinitialiser( this.detailsMttCouvSimpleSAHAM );
          this.varReinitialiser( this.detailsMttCouvSimpleSUNU );

          // CALCUL POUR SAHAM
          // tslint:disable-next-line:max-line-length
          this.determineCouvTousRisqueMonttAPayer(this.detailsMttCouvSimpleSAHAM, this.fourthFormGroup.value.puissance, this.fourthFormGroup.value.energie, this.firstFormGroup.value.situation, this.fourthFormGroup.value.valVenale, this.fourthFormGroup.value.valNoeuve, tarifsEssenceCatPersoSAHAM, tarifsDieselCatPersoSAHAM, tarifsEssenceCatSocieteSAHAM, tarifsDieselCatSocieteSAHAM, this.thirdFormGroup.value.categorie, tabChargeUtileSAHAM, this.fourthFormGroup.value.chargeUtile, ConstGarantiesSAHAM);

          // CALCUL POUR SUNU
          // tslint:disable-next-line:max-line-length
          this.determineCouvTousRisqueMonttAPayer(this.detailsMttCouvSimpleSUNU, this.fourthFormGroup.value.puissance, this.fourthFormGroup.value.energie, this.firstFormGroup.value.situation, this.fourthFormGroup.value.valVenale, this.fourthFormGroup.value.valNoeuve, tarifsEssenceCatPersoSUNU, tarifsDieselCatPersoSUNU, tarifsEssenceCatSocieteSUNU, tarifsDieselCatSocieteSUNU, this.thirdFormGroup.value.categorie, tabChargeUtileSUNU, this.fourthFormGroup.value.chargeUtile, ConstGarantiesSUNU);

          // CALCUL POUR ALLIANZ
          // tslint:disable-next-line:max-line-length
          this.determineCouvTousRisqueMonttAPayer(this.detailsMttCouvSimpleALLIANZ, this.fourthFormGroup.value.puissance, this.fourthFormGroup.value.energie, this.firstFormGroup.value.situation, this.fourthFormGroup.value.valVenale, this.fourthFormGroup.value.valNoeuve, tarifsEssenceCatPersoALLIANZ, tarifsDieselCatPersoALLIANZ, tarifsEssenceCatSocieteALLIANZ, tarifsDieselCatSocieteALLIANZ, this.thirdFormGroup.value.categorie, tabChargeUtileALLIANZ, this.fourthFormGroup.value.chargeUtile, ConstGarantiesALLIANZ);

          // CALCUL POUR SIDAM
          // tslint:disable-next-line:max-line-length
          this.determineCouvTousRisqueMonttAPayer(this.detailsMttCouvSimpleSIDAM, this.fourthFormGroup.value.puissance, this.fourthFormGroup.value.energie, this.firstFormGroup.value.situation, this.fourthFormGroup.value.valVenale, this.fourthFormGroup.value.valNoeuve, tarifsEssenceCatPersoSIDAM, tarifsDieselCatPersoSIDAM, tarifsEssenceCatSocieteSIDAM, tarifsDieselCatSocieteSIDAM, this.thirdFormGroup.value.categorie, tabChargeUtileSIDAM, this.fourthFormGroup.value.chargeUtile, ConstGarantiesSIDAM);

          break;
      }

    }

  }

  async varReinitialiser(variable: any) {
    variable.respoCivile = 0;
    variable.defenseRecours = 0;
    variable.individuelConducteur = 0;
    variable.incendie = 0;
    variable.volMainsArmees = 0;
    variable.brisDeGlaces = 0;
    variable.avanceRecours = 0;
    variable.dommagColTiersID = 0;
    variable.dommagColComplet = 0;
    variable.respoCivileReduite = 0;
    variable.primeBrut = 0;
    variable.primeNet = 0;
    variable.primeNetTot = 0;
    variable.taxe = 0;
    variable.totalApayer = 0;
    variable.this.fourthFormGroup.value.miseEnCirculation = 0;
    console.log( variable );
  }
  validationThirdForm() {

    this.isSubmitted03 = true;
    this.loadingService.chargement();
    this.firstFormGroup.reset();
    if (this.thirdFormGroup.invalid)  {
      return;
    } else {
      this.isSubmitted03 = false;
      console.log( this.thirdFormGroup );
    }
  }


  resetForm(form: FormGroup, btnClick: boolean) {
    btnClick = false;
    form.reset();
  }


  validationFourthForm() {

    this.isSubmitted02 = true;
    this.loadingService.chargement();
    if (this.fourthFormGroup.invalid) {
      return;
    } else {
      this.isSubmitted02 = false;
      console.log(this.fourthFormGroup);
    }
  }
  onChangeCategorie(event: any) {

    switch (event.target.value) {

      case 'CATPERSO':
      this.showModalVehPerso = false;
      this.showModalVehUtil = true;
      this.showModalVehSoc = true;
      this.fourthFormGroup.controls.chargeUtile.setValue('inconnu');
      break;
      case 'CATUTILE':
      this.showModalVehPerso = true;
      this.showModalVehUtil = false;
      this.showModalVehSoc = true;
      this.fourthFormGroup.controls.energie.setValue('inconnu');
      this.fourthFormGroup.controls.puissance.setValue('inconnu');
      // this.fourthFormGroup.controls.chargeUtile.reset();
      // this.fourthFormGroup.controls['chargeUtile'].reset();
      break;
      case 'CATSOCI':
      this.showModalVehPerso = true;
      this.showModalVehUtil = true;
      this.showModalVehSoc = false;
      this.fourthFormGroup.controls.chargeUtile.setValue('inconnu');
      break;

      default:
      this.showModalVehPerso = true;
      this.showModalVehUtil = true;
      this.showModalVehSoc = true;
      break;
    }
  }
  onChangeEnergie(event: any) {

      if (event.target.value === 'ESSENCE') {
          this.isEssence = true;
      } else {
        this.isEssence = false;
      }
  }
  onSelectDuree(event: any) {

    this.isSubmitted04 = true;
    this.dureeSelect = event.target.value;
    switch (event.target.value) {

      case '1':
      this.pourcentagDuree = ConstCotisation.COTA_1_MOIS;
      break;
      case '3':
      this.pourcentagDuree = ConstCotisation.COTA_3_MOIS;
      break;
      case '6':
      this.pourcentagDuree = ConstCotisation.COTA_6_MOIS;
      break;
      case '12':
      this.pourcentagDuree = ConstCotisation.COTA_12_MOIS;
      break;

      default:
      this.pourcentagDuree = 0;
      break;
    }
  }
  onSelectCartSocio(event: any) {

    switch (event.target.value) {

      case 'religieux':
      this.pourcentagCatSocio = 10;
      break;

      case 'artisan':
      this.pourcentagCatSocio = 10;
      break;

      case 'travailleur':
      this.pourcentagCatSocio = 5;
      break;

      default:
      this.pourcentagCatSocio = 0;
      break;
    }

  }


  // tslint:disable-next-line:max-line-length
  determineCouvSimpleMonttAPayer(detailsDiffMontantAPayer: any, varPuissance: string, varCarburant: string, varProfession: string, tabTarifAssuEssenceCatPerso: PuissanceTarifs[], tabTarifAssuDieselCatPerso: PuissanceTarifs[], tabTarifAssuEssenceCatSociete: PuissanceTarifs[], tabTarifAssuDieselCatSociete: PuissanceTarifs[], typevehicule: string, tabChargeUtile: ChargeUtileTarifs[], chargeutil: string, contGaranties: any) {

    // CALCUL DE LA RESPOMSABILITE CIVILE
    if (typevehicule === 'CATPERSO') {
      // tslint:disable-next-line:max-line-length
      detailsDiffMontantAPayer.respoCivile = this.tarifCategorie01Service.getResponsabiliteCivileTypePersonnel(varPuissance, varCarburant, tabTarifAssuEssenceCatPerso, tabTarifAssuDieselCatPerso);
    } else if (typevehicule === 'CATUTILE') {
      // tslint:disable-next-line:max-line-length
      detailsDiffMontantAPayer.respoCivile = this.tarifCategorie01Service.getResponsabiliteCivileTypeUtilitaire(tabChargeUtile, chargeutil);
    } else if (typevehicule === 'CATSOCI') {
      // tslint:disable-next-line:max-line-length
      detailsDiffMontantAPayer.respoCivile = this.tarifCategorie01Service.getResponsabiliteCivileTypeSociete(varPuissance, varCarburant, tabTarifAssuEssenceCatSociete, tabTarifAssuDieselCatSociete);
    }
    // Defense Recours
    detailsDiffMontantAPayer.defenseRecours = this.tarifCategorie01Service.getDefenseRecours(contGaranties);
    // Individuel Conducteur
    detailsDiffMontantAPayer.individuelConducteur = this.tarifCategorie01Service.getIndividuelConducteur(contGaranties);
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.primeBrut = (detailsDiffMontantAPayer.respoCivile + detailsDiffMontantAPayer.defenseRecours + detailsDiffMontantAPayer.individuelConducteur);
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.primeNet = ( (detailsDiffMontantAPayer.respoCivile - this.tarifCategorie01Service.reduce_respo_civile( varProfession, detailsDiffMontantAPayer.respoCivile, contGaranties ))
                                                // tslint:disable-next-line:max-line-length
                                                + detailsDiffMontantAPayer.defenseRecours + detailsDiffMontantAPayer.individuelConducteur);
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.primeNetTot = this.tarifCategorie01Service.getPrimeNetTotale( detailsDiffMontantAPayer.primeNet, contGaranties);
    detailsDiffMontantAPayer.taxe = this.tarifCategorie01Service.getTaxe( detailsDiffMontantAPayer.primeNetTot );
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.fondGarantie = this.tarifCategorie01Service.getFondGarantie( (detailsDiffMontantAPayer.respoCivile - this.tarifCategorie01Service.reduce_respo_civile( varProfession, detailsDiffMontantAPayer.respoCivile, contGaranties )) );
    // MTT TOTAL A PAYER SUR 1 AN
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.totalApayer =  (detailsDiffMontantAPayer.primeNetTot + detailsDiffMontantAPayer.taxe + detailsDiffMontantAPayer.fondGarantie +
                                        + this.tarifCategorie01Service.getMontantCEDEAO(contGaranties));
    //  <-----------------------------------------------------------------------------------------------
    /* CALCUL FINAL DES TOTAUX MONTANT A PAYER FCTION DE LA DUREE CHOISIE */
    // DEFINITIF A PAYER / DUREE
    detailsDiffMontantAPayer.prixConvertDureeTotalAPayer = (detailsDiffMontantAPayer.totalApayer * this.pourcentagDuree);
    // RESO CIVILE REDUITE / DUREE
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.respoCivile = ((detailsDiffMontantAPayer.respoCivile - this.tarifCategorie01Service.reduce_respo_civile( varProfession, detailsDiffMontantAPayer.respoCivile, contGaranties ))
                                            * this.pourcentagDuree);
    // DEFENSE RECOURS / DUREE
    detailsDiffMontantAPayer.defenseRecours = (detailsDiffMontantAPayer.defenseRecours * this.pourcentagDuree);
    // INDIV CONDUCTEUR / DUREE
    detailsDiffMontantAPayer.individuelConducteur = (detailsDiffMontantAPayer.individuelConducteur * this.pourcentagDuree);
    // PRIME BRUT / DUREE
    detailsDiffMontantAPayer.primeBrut = (detailsDiffMontantAPayer.primeBrut * this.pourcentagDuree);
    // TAXE / DUREE
    detailsDiffMontantAPayer.taxe = (detailsDiffMontantAPayer.taxe * this.pourcentagDuree);
  }

  // tslint:disable-next-line:max-line-length
  determineCouvAmelioreMonttAPayer(detailsDiffMontantAPayer: any, varPuissance: string, varCarburant: string, varProfession: string, valVenale: number, valNeuve: number, tabTarifAssuEssenceCatPerso: PuissanceTarifs[], tabTarifAssuDieselCatPerso: PuissanceTarifs[], tabTarifAssuEssenceCatSociete: PuissanceTarifs[], tabTarifAssuDieselCatSociete: PuissanceTarifs[], typevehicule: string, tabChargeUtile: ChargeUtileTarifs[], chargeutil: string, contGaranties: any) {

    // CALCUL DE LA RESPOMSABILITE CIVILE
    if (typevehicule === 'CATPERSO') {
      // tslint:disable-next-line:max-line-length
      detailsDiffMontantAPayer.respoCivile = this.tarifCategorie01Service.getResponsabiliteCivileTypePersonnel(varPuissance, varCarburant, tabTarifAssuEssenceCatPerso, tabTarifAssuDieselCatPerso);
    } else if (typevehicule === 'CATUTILE') {
      // tslint:disable-next-line:max-line-length
      detailsDiffMontantAPayer.respoCivile = this.tarifCategorie01Service.getResponsabiliteCivileTypeUtilitaire(tabChargeUtile, chargeutil);
    } else if (typevehicule === 'CATSOCI') {
      // tslint:disable-next-line:max-line-length
      detailsDiffMontantAPayer.respoCivile = this.tarifCategorie01Service.getResponsabiliteCivileTypeSociete(varPuissance, varCarburant, tabTarifAssuEssenceCatSociete, tabTarifAssuDieselCatSociete);
    }
    // Defense Recours
    detailsDiffMontantAPayer.defenseRecours = this.tarifCategorie01Service.getDefenseRecours(contGaranties);
    // Individuel Conducteur
    detailsDiffMontantAPayer.individuelConducteur = this.tarifCategorie01Service.getIndividuelConducteur(contGaranties);
    // Incendie
    detailsDiffMontantAPayer.incendie = this.tarifCategorie01Service.getIncendie(valVenale, contGaranties);
    // Vol a mains armees
    detailsDiffMontantAPayer.volMainsArmees = this.tarifCategorie01Service.getVolMainsArmees(valVenale, contGaranties);
    // Vol de vehicule
    detailsDiffMontantAPayer.volVehicule = this.tarifCategorie01Service.getVolVehicule(valVenale, contGaranties);
    // Vol accessoire
    detailsDiffMontantAPayer.volAccessoire = this.tarifCategorie01Service.getVolAccessoire(contGaranties);
    // Bris de glace
    detailsDiffMontantAPayer.brisDeGlaces = this.tarifCategorie01Service.getBrisDeGlaces(valNeuve, contGaranties);
    // Avance Recours
    detailsDiffMontantAPayer.avanceRecours = this.tarifCategorie01Service.getAvanceRecours(contGaranties);
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.primeBrut = (detailsDiffMontantAPayer.respoCivile + detailsDiffMontantAPayer.defenseRecours + detailsDiffMontantAPayer.individuelConducteur
                                          // tslint:disable-next-line:max-line-length
                                          + detailsDiffMontantAPayer.incendie + detailsDiffMontantAPayer.volMainsArmees + detailsDiffMontantAPayer.volVehicule
                                          // tslint:disable-next-line:max-line-length
                                          + detailsDiffMontantAPayer.volAccessoire + detailsDiffMontantAPayer.brisDeGlaces + detailsDiffMontantAPayer.avanceRecours);

    // APPLICATION DE LA REDUCTION POUR RESPONSA CIVILE
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.incendie = (detailsDiffMontantAPayer.incendie - this.tarifCategorie01Service.reduce_respo_civile( varProfession, detailsDiffMontantAPayer.incendie, contGaranties ));
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.volMainsArmees = (detailsDiffMontantAPayer.volMainsArmees - this.tarifCategorie01Service.reduce_respo_civile( varProfession, detailsDiffMontantAPayer.volMainsArmees, contGaranties ));
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.volVehicule = (detailsDiffMontantAPayer.volVehicule - this.tarifCategorie01Service.reduce_respo_civile( varProfession, detailsDiffMontantAPayer.volVehicule, contGaranties ));
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.volAccessoire = (detailsDiffMontantAPayer.volAccessoire - this.tarifCategorie01Service.reduce_respo_civile( varProfession, detailsDiffMontantAPayer.volAccessoire, contGaranties ));
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.brisDeGlaces = (detailsDiffMontantAPayer.brisDeGlaces - this.tarifCategorie01Service.reduce_respo_civile( varProfession, detailsDiffMontantAPayer.brisDeGlaces, contGaranties ));

    // APPLICATION DE LA REDUCTION COMMERCIALE
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.incendie = (detailsDiffMontantAPayer.incendie - this.tarifCategorie01Service.reduce_commerciale(detailsDiffMontantAPayer.incendie, contGaranties ));
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.volMainsArmees = (detailsDiffMontantAPayer.volMainsArmees - this.tarifCategorie01Service.reduce_commerciale(detailsDiffMontantAPayer.volMainsArmees, contGaranties ));
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.volVehicule = (detailsDiffMontantAPayer.volVehicule - this.tarifCategorie01Service.reduce_commerciale(detailsDiffMontantAPayer.volVehicule, contGaranties ));
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.volAccessoire = (detailsDiffMontantAPayer.volAccessoire - this.tarifCategorie01Service.reduce_commerciale(detailsDiffMontantAPayer.volAccessoire, contGaranties ));

    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.primeNet = ( (detailsDiffMontantAPayer.respoCivile - this.tarifCategorie01Service.reduce_respo_civile( varProfession, detailsDiffMontantAPayer.respoCivile, contGaranties ))
                                                // tslint:disable-next-line:max-line-length
                                                + detailsDiffMontantAPayer.defenseRecours + detailsDiffMontantAPayer.individuelConducteur
                                                // tslint:disable-next-line:max-line-length
                                                + detailsDiffMontantAPayer.incendie + detailsDiffMontantAPayer.volMainsArmees + detailsDiffMontantAPayer.volVehicule
                                                // tslint:disable-next-line:max-line-length
                                                + detailsDiffMontantAPayer.volAccessoire + detailsDiffMontantAPayer.brisDeGlaces + detailsDiffMontantAPayer.avanceRecours);
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.primeNetTot = this.tarifCategorie01Service.getPrimeNetTotale( detailsDiffMontantAPayer.primeNet, contGaranties);
    detailsDiffMontantAPayer.taxe = this.tarifCategorie01Service.getTaxe( detailsDiffMontantAPayer.primeNetTot );
    detailsDiffMontantAPayer.totalApayer =  (detailsDiffMontantAPayer.primeNetTot + detailsDiffMontantAPayer.taxe +
                                        // tslint:disable-next-line:max-line-length
                                        this.tarifCategorie01Service.getFondGarantie( (detailsDiffMontantAPayer.respoCivile - this.tarifCategorie01Service.reduce_respo_civile(varProfession, detailsDiffMontantAPayer.respoCivile, contGaranties)) )
                                        + this.tarifCategorie01Service.getMontantCEDEAO(contGaranties));

    // <-----------------------------------------------------------------------------------------------------
    // CALCUL FINAL DES MONTANT A PAYER EN FONCTION DE LA DUREE CHOISIE
    detailsDiffMontantAPayer.prixConvertDureeTotalAPayer = (detailsDiffMontantAPayer.totalApayer * this.pourcentagDuree);
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.fondGarantie = this.tarifCategorie01Service.getFondGarantie( (detailsDiffMontantAPayer.respoCivile - this.tarifCategorie01Service.reduce_respo_civile(varProfession, detailsDiffMontantAPayer.respoCivile, contGaranties)) );
    detailsDiffMontantAPayer.respoCivile = (detailsDiffMontantAPayer.respoCivile * this.pourcentagDuree);
    detailsDiffMontantAPayer.defenseRecours = (detailsDiffMontantAPayer.defenseRecours * this.pourcentagDuree);
    detailsDiffMontantAPayer.individuelConducteur = (detailsDiffMontantAPayer.individuelConducteur * this.pourcentagDuree);
    detailsDiffMontantAPayer.incendie = (detailsDiffMontantAPayer.incendie * this.pourcentagDuree);
    detailsDiffMontantAPayer.volMainsArmees = (detailsDiffMontantAPayer.volMainsArmees * this.pourcentagDuree);
    detailsDiffMontantAPayer.volVehicule = (detailsDiffMontantAPayer.volVehicule * this.pourcentagDuree);
    detailsDiffMontantAPayer.volAccessoire = (detailsDiffMontantAPayer.volAccessoire * this.pourcentagDuree);
    detailsDiffMontantAPayer.brisDeGlaces = (detailsDiffMontantAPayer.brisDeGlaces * this.pourcentagDuree);
    detailsDiffMontantAPayer.avanceRecours = (detailsDiffMontantAPayer.avanceRecours * this.pourcentagDuree);
    detailsDiffMontantAPayer.taxe = (detailsDiffMontantAPayer.taxe * this.pourcentagDuree);

  }

  // tslint:disable-next-line:max-line-length
  determineCouvCollisionMonttAPayer(detailsDiffMontantAPayer: any, varPuissance: string, varCarburant: string, varProfession: string, valVenale: number, valNeuve: number, tabTarifAssuEssenceCatPerso: PuissanceTarifs[], tabTarifAssuDieselCatPerso: PuissanceTarifs[], tabTarifAssuEssenceCatSociete: PuissanceTarifs[], tabTarifAssuDieselCatSociete: PuissanceTarifs[], typevehicule: string, tabChargeUtile: ChargeUtileTarifs[], chargeutil: string, contGaranties: any) {

    // CALCUL DE LA RESPONSABILITE CIVILE
    if (typevehicule === 'CATPERSO') {
      // tslint:disable-next-line:max-line-length
      detailsDiffMontantAPayer.respoCivile = this.tarifCategorie01Service.getResponsabiliteCivileTypePersonnel(varPuissance, varCarburant, tabTarifAssuEssenceCatPerso, tabTarifAssuDieselCatPerso);
    } else if (typevehicule === 'CATUTILE') {
      // tslint:disable-next-line:max-line-length
      detailsDiffMontantAPayer.respoCivile = this.tarifCategorie01Service.getResponsabiliteCivileTypeUtilitaire(tabChargeUtile, chargeutil);
    } else if (typevehicule === 'CATSOCI') {
      // tslint:disable-next-line:max-line-length
      detailsDiffMontantAPayer.respoCivile = this.tarifCategorie01Service.getResponsabiliteCivileTypeSociete(varPuissance, varCarburant, tabTarifAssuEssenceCatSociete, tabTarifAssuDieselCatSociete);
    }
    // Defense Recours
    detailsDiffMontantAPayer.defenseRecours = this.tarifCategorie01Service.getDefenseRecours(contGaranties);
    // Individuel Conducteur
    detailsDiffMontantAPayer.individuelConducteur = this.tarifCategorie01Service.getIndividuelConducteur(contGaranties);
    // incendie
    detailsDiffMontantAPayer.incendie = this.tarifCategorie01Service.getIncendie(valVenale, contGaranties);
    // Vol a mains armees
    detailsDiffMontantAPayer.volMainsArmees = this.tarifCategorie01Service.getVolMainsArmees(valVenale, contGaranties);
    // Vol de vehicule
    detailsDiffMontantAPayer.volVehicule = this.tarifCategorie01Service.getVolVehicule(valVenale, contGaranties);
    // Vol accessoire
    detailsDiffMontantAPayer.volAccessoire = this.tarifCategorie01Service.getVolAccessoire(contGaranties);
    // Bris de glace
    detailsDiffMontantAPayer.brisDeGlaces = this.tarifCategorie01Service.getBrisDeGlaces(valNeuve, contGaranties);
    // Avance Recours
    detailsDiffMontantAPayer.avanceRecours = this.tarifCategorie01Service.getAvanceRecours(contGaranties);
    // Dommage Collision Tiers Identifiable
    detailsDiffMontantAPayer.dommagColTiersID = this.tarifCategorie01Service.getDommageCollisionTiersID(valNeuve, contGaranties);
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.primeBrut = (detailsDiffMontantAPayer.respoCivile + detailsDiffMontantAPayer.defenseRecours + detailsDiffMontantAPayer.individuelConducteur
                                          // tslint:disable-next-line:max-line-length
                                          + detailsDiffMontantAPayer.incendie + detailsDiffMontantAPayer.volMainsArmees + detailsDiffMontantAPayer.volVehicule
                                          + detailsDiffMontantAPayer.volAccessoire + detailsDiffMontantAPayer.brisDeGlaces
                                          + detailsDiffMontantAPayer.avanceRecours + detailsDiffMontantAPayer.dommagColTiersID);

    // APPLICATION DE LA REDUCTION POUR RESPONSA CIVILE
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.incendie = (detailsDiffMontantAPayer.incendie - this.tarifCategorie01Service.reduce_respo_civile( varProfession, detailsDiffMontantAPayer.incendie, contGaranties ));
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.volMainsArmees = (detailsDiffMontantAPayer.volMainsArmees - this.tarifCategorie01Service.reduce_respo_civile( varProfession, detailsDiffMontantAPayer.volMainsArmees, contGaranties ));
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.volVehicule = (detailsDiffMontantAPayer.volVehicule - this.tarifCategorie01Service.reduce_respo_civile( varProfession, detailsDiffMontantAPayer.volVehicule, contGaranties ));
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.volAccessoire = (detailsDiffMontantAPayer.volAccessoire - this.tarifCategorie01Service.reduce_respo_civile( varProfession, detailsDiffMontantAPayer.volAccessoire, contGaranties ));
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.brisDeGlaces = (detailsDiffMontantAPayer.brisDeGlaces - this.tarifCategorie01Service.reduce_respo_civile( varProfession, detailsDiffMontantAPayer.brisDeGlaces, contGaranties ));
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.dommagColTiersID = (detailsDiffMontantAPayer.dommagColTiersID - this.tarifCategorie01Service.reduce_respo_civile( varProfession, detailsDiffMontantAPayer.dommagColTiersID, contGaranties ));

    // APPLICATION DE LA REDUCTION COMMERCIALE
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.incendie = (detailsDiffMontantAPayer.incendie - this.tarifCategorie01Service.reduce_commerciale(detailsDiffMontantAPayer.incendie, contGaranties ));
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.volMainsArmees = (detailsDiffMontantAPayer.volMainsArmees - this.tarifCategorie01Service.reduce_commerciale(detailsDiffMontantAPayer.volMainsArmees, contGaranties ));
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.volVehicule = (detailsDiffMontantAPayer.volVehicule - this.tarifCategorie01Service.reduce_commerciale(detailsDiffMontantAPayer.volVehicule, contGaranties ));
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.volAccessoire = (detailsDiffMontantAPayer.volAccessoire - this.tarifCategorie01Service.reduce_commerciale(detailsDiffMontantAPayer.volAccessoire, contGaranties ));

    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.fondGarantie = this.tarifCategorie01Service.getFondGarantie( (detailsDiffMontantAPayer.respoCivile - this.tarifCategorie01Service.reduce_respo_civile(varProfession, this.detailsMttCouvSimpleSAHAM.respoCivile, contGaranties)) );
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.primeNet = ( (detailsDiffMontantAPayer.respoCivile - this.tarifCategorie01Service.reduce_respo_civile( varProfession, detailsDiffMontantAPayer.respoCivile, contGaranties ))
                                                // tslint:disable-next-line:max-line-length
                                                + detailsDiffMontantAPayer.defenseRecours + detailsDiffMontantAPayer.individuelConducteur + detailsDiffMontantAPayer.incendie
                                                // tslint:disable-next-line:max-line-length
                                                + detailsDiffMontantAPayer.volMainsArmees + detailsDiffMontantAPayer.volVehicule + detailsDiffMontantAPayer.volAccessoire
                                                // tslint:disable-next-line:max-line-length
                                                + detailsDiffMontantAPayer.brisDeGlaces + detailsDiffMontantAPayer.avanceRecours + detailsDiffMontantAPayer.dommagColTiersID);
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.primeNetTot = this.tarifCategorie01Service.getPrimeNetTotale( detailsDiffMontantAPayer.primeNet, contGaranties);
    detailsDiffMontantAPayer.taxe = this.tarifCategorie01Service.getTaxe( detailsDiffMontantAPayer.primeNetTot );
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.totalApayer =  (detailsDiffMontantAPayer.primeNetTot + detailsDiffMontantAPayer.taxe + detailsDiffMontantAPayer.fondGarantie
                                        + this.tarifCategorie01Service.getMontantCEDEAO(contGaranties));

    // CALCUL FINAL DU MONTANT A PAYER FCTION DE LA DUREE CHOISIE
    detailsDiffMontantAPayer.prixConvertDureeTotalAPayer = (detailsDiffMontantAPayer.totalApayer * this.pourcentagDuree);
    detailsDiffMontantAPayer.respoCivile = (detailsDiffMontantAPayer.respoCivile * this.pourcentagDuree);
    detailsDiffMontantAPayer.fondGarantie = (detailsDiffMontantAPayer.fondGarantie * this.pourcentagDuree);
    detailsDiffMontantAPayer.defenseRecours = (detailsDiffMontantAPayer.defenseRecours * this.pourcentagDuree);
    detailsDiffMontantAPayer.individuelConducteur = (detailsDiffMontantAPayer.individuelConducteur * this.pourcentagDuree);
    detailsDiffMontantAPayer.incendie = (detailsDiffMontantAPayer.incendie * this.pourcentagDuree);
    detailsDiffMontantAPayer.volMainsArmees = (detailsDiffMontantAPayer.volMainsArmees * this.pourcentagDuree);
    detailsDiffMontantAPayer.volVehicule = (detailsDiffMontantAPayer.volVehicule * this.pourcentagDuree);
    detailsDiffMontantAPayer.volAccessoire = (detailsDiffMontantAPayer.volAccessoire * this.pourcentagDuree);
    detailsDiffMontantAPayer.brisDeGlaces = (detailsDiffMontantAPayer.brisDeGlaces * this.pourcentagDuree);
    detailsDiffMontantAPayer.avanceRecours = (detailsDiffMontantAPayer.avanceRecours * this.pourcentagDuree);
    detailsDiffMontantAPayer.dommagColTiersID = (detailsDiffMontantAPayer.dommagColTiersID * this.pourcentagDuree);
    detailsDiffMontantAPayer.taxe = (detailsDiffMontantAPayer.taxe * this.pourcentagDuree);

  }

  // tslint:disable-next-line:max-line-length
  determineCouvTousRisqueMonttAPayer(detailsDiffMontantAPayer: any, varPuissance: string, varCarburant: string, varProfession: string, valVenale: number, valNeuve: number, tabTarifAssuEssenceCatPerso: PuissanceTarifs[], tabTarifAssuDieselCatPerso: PuissanceTarifs[], tabTarifAssuEssenceCatSociete: PuissanceTarifs[], tabTarifAssuDieselCatSociete: PuissanceTarifs[], typevehicule: string, tabChargeUtile: ChargeUtileTarifs[], chargeutil: string, contGaranties: any) {

    // CALCUL DE LA RESPONSABILITE CIVILE
    if (typevehicule === 'CATPERSO') {
      // tslint:disable-next-line:max-line-length
      detailsDiffMontantAPayer.respoCivile = this.tarifCategorie01Service.getResponsabiliteCivileTypePersonnel(varPuissance, varCarburant, tabTarifAssuEssenceCatPerso, tabTarifAssuDieselCatPerso);
    } else if (typevehicule === 'CATUTILE') {
      // tslint:disable-next-line:max-line-length
      detailsDiffMontantAPayer.respoCivile = this.tarifCategorie01Service.getResponsabiliteCivileTypeUtilitaire(tabChargeUtile, chargeutil);
    } else if (typevehicule === 'CATSOCI') {
      // tslint:disable-next-line:max-line-length
      detailsDiffMontantAPayer.respoCivile = this.tarifCategorie01Service.getResponsabiliteCivileTypeSociete(varPuissance, varCarburant, tabTarifAssuEssenceCatSociete, tabTarifAssuDieselCatSociete);
    }

    // Defense Recours
    detailsDiffMontantAPayer.defenseRecours = this.tarifCategorie01Service.getDefenseRecours(contGaranties);
    // Individuel Conducteur
    detailsDiffMontantAPayer.individuelConducteur = this.tarifCategorie01Service.getIndividuelConducteur(contGaranties);
    // Incendie
    detailsDiffMontantAPayer.incendie = this.tarifCategorie01Service.getIncendie(valVenale, contGaranties);
    // Vol a mains armees
    detailsDiffMontantAPayer.volMainsArmees = this.tarifCategorie01Service.getVolMainsArmees(valVenale, contGaranties);
    // Vol de vehicule
    detailsDiffMontantAPayer.volVehicule = this.tarifCategorie01Service.getVolVehicule(valVenale, contGaranties);
    // Vol accessoire
    detailsDiffMontantAPayer.volAccessoire = this.tarifCategorie01Service.getVolAccessoire(contGaranties);
    // Bris de glace
    detailsDiffMontantAPayer.brisDeGlaces = this.tarifCategorie01Service.getBrisDeGlaces(valNeuve, contGaranties);
    // Avance Recours
    detailsDiffMontantAPayer.avanceRecours = this.tarifCategorie01Service.getAvanceRecours(contGaranties);
    // Dommage Collision Complet
    detailsDiffMontantAPayer.dommagColComplet = this.tarifCategorie01Service.getDommageCollisionComplete(valNeuve, contGaranties);
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.primeBrut = (detailsDiffMontantAPayer.respoCivile 
                                          + detailsDiffMontantAPayer.defenseRecours 
                                          + detailsDiffMontantAPayer.individuelConducteur
                                          // tslint:disable-next-line:max-line-length
                                          + detailsDiffMontantAPayer.incendie 
                                          + detailsDiffMontantAPayer.volMainsArmees 
                                          + detailsDiffMontantAPayer.volVehicule
                                          + detailsDiffMontantAPayer.volAccessoire 
                                          + detailsDiffMontantAPayer.brisDeGlaces
                                          + detailsDiffMontantAPayer.avanceRecours 
                                          + detailsDiffMontantAPayer.dommagColComplet);

    // APPLICATION DE LA REDUCTION POUR RESPONSA CIVILE
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.incendie = (detailsDiffMontantAPayer.incendie - this.tarifCategorie01Service.reduce_respo_civile( varProfession, detailsDiffMontantAPayer.incendie, contGaranties ));
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.volMainsArmees = (detailsDiffMontantAPayer.volMainsArmees - this.tarifCategorie01Service.reduce_respo_civile( varProfession, detailsDiffMontantAPayer.volMainsArmees, contGaranties ));
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.volVehicule = (detailsDiffMontantAPayer.volVehicule - this.tarifCategorie01Service.reduce_respo_civile( varProfession, detailsDiffMontantAPayer.volVehicule, contGaranties ));
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.volAccessoire = (detailsDiffMontantAPayer.volAccessoire - this.tarifCategorie01Service.reduce_respo_civile( varProfession, detailsDiffMontantAPayer.volAccessoire, contGaranties ));
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.brisDeGlaces = (detailsDiffMontantAPayer.brisDeGlaces - this.tarifCategorie01Service.reduce_respo_civile( varProfession, detailsDiffMontantAPayer.brisDeGlaces, contGaranties ));
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.dommagColComplet = (detailsDiffMontantAPayer.dommagColComplet - this.tarifCategorie01Service.reduce_respo_civile( varProfession, detailsDiffMontantAPayer.dommagColComplet, contGaranties ));

    // APPLICATION DE LA REDUCTION COMMERCIALE
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.incendie = (detailsDiffMontantAPayer.incendie - this.tarifCategorie01Service.reduce_commerciale(detailsDiffMontantAPayer.incendie, contGaranties ));
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.volMainsArmees = (detailsDiffMontantAPayer.volMainsArmees - this.tarifCategorie01Service.reduce_commerciale(detailsDiffMontantAPayer.volMainsArmees, contGaranties ));
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.volVehicule = (detailsDiffMontantAPayer.volVehicule - this.tarifCategorie01Service.reduce_commerciale(detailsDiffMontantAPayer.volVehicule, contGaranties ));
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.volAccessoire = (detailsDiffMontantAPayer.volAccessoire - this.tarifCategorie01Service.reduce_commerciale(detailsDiffMontantAPayer.volAccessoire, contGaranties ));

    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.fondGarantie = this.tarifCategorie01Service.getFondGarantie( (detailsDiffMontantAPayer.respoCivile - this.tarifCategorie01Service.reduce_respo_civile(varProfession, this.detailsMttCouvSimpleSAHAM.respoCivile, contGaranties)) );
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.primeNet = ( (detailsDiffMontantAPayer.respoCivile - this.tarifCategorie01Service.reduce_respo_civile( varProfession, detailsDiffMontantAPayer.respoCivile, contGaranties ))
                                                // tslint:disable-next-line:max-line-length
                                                + detailsDiffMontantAPayer.defenseRecours + detailsDiffMontantAPayer.individuelConducteur
                                                // tslint:disable-next-line:max-line-length
                                                + detailsDiffMontantAPayer.incendie + detailsDiffMontantAPayer.volMainsArmees + detailsDiffMontantAPayer.volVehicule
                                                + detailsDiffMontantAPayer.volAccessoire + detailsDiffMontantAPayer.brisDeGlaces
                                                + detailsDiffMontantAPayer.avanceRecours + detailsDiffMontantAPayer.dommagColComplet);
    // tslint:disable-next-line:max-line-length
    detailsDiffMontantAPayer.primeNetTot = this.tarifCategorie01Service.getPrimeNetTotale( detailsDiffMontantAPayer.primeNet, contGaranties);
    detailsDiffMontantAPayer.taxe = this.tarifCategorie01Service.getTaxe( detailsDiffMontantAPayer.primeNetTot );
    detailsDiffMontantAPayer.totalApayer = (detailsDiffMontantAPayer.primeNetTot + detailsDiffMontantAPayer.taxe
                                            // tslint:disable-next-line:max-line-length
                                            + detailsDiffMontantAPayer.fondGarantie + this.tarifCategorie01Service.getMontantCEDEAO(contGaranties));

    // CALCUL FINAL DU MONTANT A PAYER FCTION DE LA DUREE CHOISIE
    detailsDiffMontantAPayer.prixConvertDureeTotalAPayer = (detailsDiffMontantAPayer.totalApayer * this.pourcentagDuree);

    detailsDiffMontantAPayer.respoCivile = (detailsDiffMontantAPayer.respoCivile * this.pourcentagDuree);
    detailsDiffMontantAPayer.defenseRecours = (detailsDiffMontantAPayer.defenseRecours * this.pourcentagDuree);
    detailsDiffMontantAPayer.individuelConducteur = (detailsDiffMontantAPayer.individuelConducteur * this.pourcentagDuree);
    detailsDiffMontantAPayer.incendie = (detailsDiffMontantAPayer.incendie * this.pourcentagDuree);
    detailsDiffMontantAPayer.fondGarantie = (detailsDiffMontantAPayer.fondGarantie * this.pourcentagDuree);
    detailsDiffMontantAPayer.volVehicule = (detailsDiffMontantAPayer.volVehicule * this.pourcentagDuree);
    detailsDiffMontantAPayer.volAccessoire = (detailsDiffMontantAPayer.volAccessoire * this.pourcentagDuree);
    detailsDiffMontantAPayer.volMainsArmees = (detailsDiffMontantAPayer.volMainsArmees * this.pourcentagDuree);
    detailsDiffMontantAPayer.brisDeGlaces = (detailsDiffMontantAPayer.brisDeGlaces * this.pourcentagDuree);
    detailsDiffMontantAPayer.avanceRecours = (detailsDiffMontantAPayer.avanceRecours * this.pourcentagDuree);
    detailsDiffMontantAPayer.dommagColComplet = (detailsDiffMontantAPayer.dommagColComplet * this.pourcentagDuree);
    detailsDiffMontantAPayer.taxe = (detailsDiffMontantAPayer.taxe * this.pourcentagDuree);

  }

  ngOnInit() {

    this.firstFormGroup  =  this.formBuilder.group({
      permis: [''],
      situation: ['', Validators.required]
    });
    this.secondFormGroup = this.formBuilder.group({
      choice: ['', Validators.required]
    });
    this.thirdFormGroup = this.formBuilder.group({
      categorie: ['', Validators.required]
    });
    this.firthFormGroup = this.formBuilder.group({
      duree: ['', Validators.required]
    });
    this.fourthFormGroup = this.formBuilder.group({
      marque: ['', Validators.required],
      immat: ['', Validators.required],
      energie: ['', Validators.required],
      puissance: ['', Validators.required],
      chargeUtile: ['', Validators.required],
      valNoeuve: ['', Validators.required],
      valVenale: ['', Validators.required],
      miseEnCirculation: ['', Validators.required]
    });

    // Reinitialisation des variables assurances
    this.detailsMttCouvSimpleSUNU =  {
      respoCivile: 0,
      defenseRecours: 0,
      individuelConducteur: 0,
      incendie: 0,
      volMainsArmees: 0,
      volVehicule: 0,
      volAccessoire: 0,
      brisDeGlaces: 0,
      avanceRecours: 0,
      dommagColTiersID: 0,
      dommagColComplet: 0,
      respoCivileReduite: 0,
      primeBrut: 0,
      primeNet: 0,
      primeNetTot: 0,
      taxe: 0,
      totalApayer: 0,
      fondGarantie: 0,
      prixConvertDureeTotalAPayer: 0
    };
    this.detailsMttCouvSimpleALLIANZ = {
      respoCivile: 0,
      defenseRecours: 0,
      individuelConducteur: 0,
      incendie: 0,
      volMainsArmees: 0,
      volVehicule: 0,
      volAccessoire: 0,
      brisDeGlaces: 0,
      avanceRecours: 0,
      dommagColTiersID: 0,
      dommagColComplet: 0,
      respoCivileReduite: 0,
      primeBrut: 0,
      primeNet: 0,
      primeNetTot: 0,
      taxe: 0,
      totalApayer: 0,
      fondGarantie: 0,
      prixConvertDureeTotalAPayer: 0
    };
    this.detailsMttCouvSimpleSIDAM = {
      respoCivile: 0,
      defenseRecours: 0,
      individuelConducteur: 0,
      incendie: 0,
      volMainsArmees: 0,
      volVehicule: 0,
      volAccessoire: 0,
      brisDeGlaces: 0,
      avanceRecours: 0,
      dommagColTiersID: 0,
      dommagColComplet: 0,
      respoCivileReduite: 0,
      primeBrut: 0,
      primeNet: 0,
      primeNetTot: 0,
      taxe: 0,
      totalApayer: 0,
      fondGarantie: 0,
      prixConvertDureeTotalAPayer: 0
    };

  }

  
  /**
   * Date du jour
   * getDate
   *
   */
  public getDate(): Date {
    return new Date();
  }

  myDate = Date.now();


}
