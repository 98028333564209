import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHandler } from '@angular/common/http';

import { Tarifs } from '../../_shared/tarifs';
import { MESTARIFS } from '../../_shared/tarifsVoyage';
import { MESTARIFSCOMAR } from '../../_shared/tarfisVoyage01';
import { AssuOffre } from '../../_shared/assurOffre';

import { LoadingService } from '../../_services/loading.service';
import { environment } from '../../../environments/environment';
import { UserService } from '../../_services/user.service';
import { Md5 } from 'ts-md5/dist/md5';
//import { MD5 } from 'md5';

import { ChoixDateValidator } from '../../_validators/chdate.validator';
import { SpinnerComponent } from '../../_chargement/spinner/spinner.component';
import { UpperCasePipe } from '@angular/common';
import { stringify } from '@angular/compiler/src/util';
//import { MD5 } from 'crypto-js';

@Component({
  selector: 'app-voyage',
  templateUrl: './voyage.component.html',
  styleUrls: ['./voyage.component.scss']
})

export class VoyageComponent implements OnInit {

  env = environment;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  offreAssur: AssuOffre[];
  private mesTarifs: Tarifs[];
  private mesTarifsCOMAR = [];
  isSubmitted: boolean;
  isSubmitted2: boolean;
  isSubmittedPrice: false;
  formRealSelectDest = '';
  formRealSelectAge = '';
  hiddenInfos: boolean;
  search002 = false;
  isConnected: boolean;
  md5 = new Md5();
  upper = new UpperCasePipe();
  payedSucces = false;
  payedError = false;
  loginForm: FormGroup;
  emailIncorrect = false;
  isAuth: boolean;
  url: string;

  // tslint:disable-next-line:max-line-length
  constructor(private formBuilder: FormBuilder, private router: Router, private loadingService: LoadingService,
              private httpClient: HttpClient, private userService: UserService) { }

  // convenience getter for easy access to form fields
  get s() { return this.secondFormGroup.controls; }
  get f() { return this.loginForm.controls; }

  validationSecondForm() {

    this.loadingService.chargement();
    this.isSubmitted2 = true;
    if (this.secondFormGroup.invalid)  {
      return;
    } else {

      // Initialisation
      this.search002 = false;
      this.offreAssur[0].prix = 0;
      this.offreAssur[1].prix = 0;

      // Recherche des price
      const val = this.secondFormGroup.value;
      this.recupRealSelectValues(val.destination, val.age);
      // CLACUL ASSURANCE SAHAM
      // tslint:disable-next-line:no-shadowed-variable
      this.mesTarifs.forEach((element) => {
        if (element.destination === val.destination && element.age === val.age && element.duree === parseInt(val.duree, 10)) {
          this.offreAssur[0].prix = element.prix;
          return;
        }
      });
      // CALCUL ASSURANCE COMAR
      this.mesTarifsCOMAR.forEach((item) => {
        if (item.destination === val.destination && item.duree === parseInt(val.duree, 10)) {
          this.offreAssur[1].prix = item.prix;
          this.search002 = true;
          return;
        }
      });

    }

  }
  recupRealSelectValues(destination: any, age: any) {
    switch (destination) {
      case 'd1':
      this.formRealSelectDest = 'AFRIQUE DE L\'OUEST';
      break;
      case 'd2':
      this.formRealSelectDest = 'SHENGHEN ET AFRIQUE DU NORD';
      break;
      case 'd3':
      this.formRealSelectDest = 'MONDE ENTIER';
      break;
    }
    switch (age) {

      case 'bebe':
      this.formRealSelectAge = '0 à 1 AN';
      break;
      case 'enfant':
      this.formRealSelectAge = '2 à 11 ANS';
      break;
      case 'adulte':
      this.formRealSelectAge = '12 à 80 ANS';
      break;
    }
  }
  souscription(offreAssur: any) {

    let encrypt: any 
    //encrypt = [this.userService.getUserAllInfos().shopId, offreAssur.prix, this.userService.getUserAllInfos().customerNumber].join(';');
      
    encrypt = ['601ea4c4a1e9900012a82239',offreAssur.prix,'MeBtSV7LMCo3uuXgf9y0x73L'].join(';');
    encrypt = Md5.hashAsciiStr(encrypt);
    encrypt = this.upper.transform(encrypt);
    //encrypt = encrypt.toUpperCase();
    console.log(encrypt);
    
    const transactionObj = {
      //601ea4c4a1e9900012a8223
      //secret_key: 'MeBtSV7LMCo3uuXgf9y0x73L'
      shopId: '601ea4c4a1e9900012a82239',
      vendorId: null,
      amount: offreAssur.prix,
      customerNumber: this.userService.getUserAllInfos().customerNumber,
      orderNumber: null,
      signature: encrypt
      //signature: 65577BE7C2A2A009DC0E0BE34D19E0FE fonctionne 7d8631a61eb37edb363205a53c3f5a20
    };

    

    console.log(transactionObj.signature);
    console.log(transactionObj.amount);

    // Creating Transaction
    // tslint:disable-next-line:max-line-length
    this.userService.getCreateTransaction(transactionObj.shopId, transactionObj.vendorId, transactionObj.amount, transactionObj.customerNumber, transactionObj.orderNumber, transactionObj.signature).subscribe(

      (data) => {

        console.log( data );
        console.log(data['url']);
        this.url = data['url'];
        this.payedSucces = true;
        
        window.location.href = this.url;
        // console.log( 'affiche du data' ); console.log( data );
        // console.log( 'affiche du data succes' ); console.log( data['success'] );
        // console.log( 'affiche du data user' ); console.log( data['user'] );
        // console.log( 'affiche du data id' ); console.log( data['user'].id );
        // console.log( 'affiche userCourrent' ); console.log( this.userCurrent );

        // link register
        // this.userService.getRegisterOnApi(this.userCurrent).subscribe(
        //   (response) => {

        //     // console.log( 'affiche response succes' ); console.log( response['success'] );
        //     // console.log( 'affiche response user' ); console.log( response[0].user );
        //     // console.log( 'affiche userCourent' ); console.log( this.userCurrent );
        //     if (response['success'] === true) {

        //       this.isErrorEmail = false;
        //       this.isRegisterSuccess = true;
        //     }
        //     // Email deja existant
        //     // console.log('contenu du lie response error email'); console.log(response['error'].email);
        //     if (response['error'].email !== undefined) {
        //       this.isErrorEmail = true;
        //     }
        //   },
        //   (error) => {
        //     console.log( error );
        //   }
        // );

      },
      (error) => {
        console.log( error );
        this.payedError = true;
      }
    );




  }

  /* choixDateNaissance() {

    const val = this.assurForm.value;
    // Calcul de l'age du client
    const timeDiff = Math.abs(Date.now() - new Date(val.dateNaissance).getTime() );
    const ageClient = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
    // bebe=[0 - 1 AN] / enfant=[2 - 11 ANS] / adulte=[12 - 80 ANS]
    if (ageClient <= 1) {
      this.typeClient = 'bebe';
    } else if ( 2 <= ageClient && ageClient <= 11) {
      this.typeClient = 'enfant';
    } else if (12 <= ageClient && ageClient <= 80) {
      this.typeClient = 'adulte';
    }
  } */

  validationLogin() {

    this.isSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    } else {

      this.userService.getAuthOnApi(this.loginForm.value.email, this.loginForm.value.password).subscribe(

        (data) => {

          // console.log('afficher data'); console.log(data['error']);
          if (data['success']) {

            sessionStorage.setItem('firstname', data['user'][0].firstname);
            sessionStorage.setItem('lastname', data['user'][0].lastname);
            sessionStorage.setItem('email', data['user'][0].email);
            sessionStorage.setItem('phone', data['user'][0].phone);
            sessionStorage.setItem('birthday', data['user'][0].birthday);
            sessionStorage.setItem('customerNumber', data['user'][0].customerNumber);
            sessionStorage.setItem('shopId', data['user'][0].shopId);
            sessionStorage.setItem('has_payqin_account', this.userService.convertBooleanToString(data['user'][0].has_payqin_account));
            sessionStorage.setItem('created_at', data['user'][0].created_at);
            sessionStorage.setItem('updated_at', data['user'][0].updated_at);
            sessionStorage.setItem('isAuth', 'true');
            this.env.isAuth = true;
            this.isConnected = this.userService.isUserAuth();
          }
          // Password Incorrect
          if (data['error']) {
            this.emailIncorrect = true;
            this.isConnected = this.userService.isUserAuth();
          }
        },
        (error) => {
          console.log(error + 'erreur requte');
        }
      );

    }
  }

  ngOnInit() {

    this.secondFormGroup = this.formBuilder.group({
      // dateNaissance: ['', Validators.compose([Validators.required, ChoixDateValidator.validCdate])],
      // agree: ['', Validators.required],
      destination: ['', Validators.required],
      duree: ['', Validators.required],
      age: ['', Validators.required],
      isSouscribe: ['']
    });
    this.thirdFormGroup = this.formBuilder.group({
      // dateNaissance: ['', Validators.compose([Validators.required, ChoixDateValidator.validCdate])],
      // agree: ['', Validators.required],
      isSouscribe: ['']
    });
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
    //this.isConnected = this.userService.isUserAuth();

    this.isAuth = this.userService.isUserAuth();
    this.offreAssur = [
      {
        libelle: 'SAHAM ASSURANCE',
        prix: 0,
        taux: 0
      },
      {
        libelle: 'COMAR ASSURANCE',
        prix: 0,
        taux: 0
      }
    ];
    this.isSubmitted = false;
    this.isSubmitted2 = false;
    this.mesTarifs = MESTARIFS;
    this.mesTarifsCOMAR = MESTARIFSCOMAR;
    this.isConnected = this.userService.isUserAuth();

  }

}