export const ConstGarantiesSAHAM = {
  DEFENSE_RECOURS: 7950,
  //INDIVIDUEL_CONDUCTEUR: 3500,
  INDIVIDUEL_CONDUCTEUR: 2860,
  REDUCE_PROF_RELIGIEUX: 10,
  //REDUCE_PROF_AUTRE: 5,
  REDUCE_PROF_ARTISAN: 10,
  REDUCE_PROF_TRAVAILLEUR: 5,
  REDUCE_PROF_AUTRE: 0,
  //REDUCE_INCENDIE: 0.007,
  REDUCE_INCENDIE: 0.0032,
  //REDUCE_VOL_VOL_MAINS_LEVEE: 0.007,
  REDUCE_VOL_VOL_MAINS_LEVEE: 0.006,
  //REDUCE_BRIS_GLACE: 0.003,
  REDUCE_BRIS_GLACE: 0.0024,
  //REDUCE_TIER_COLLISION_ID: 0.045,
  REDUCE_TIER_COLLISION_ID: 0.036,
  //REDUCE_TIER_COLLISION_COMPLET: 0.075,
  REDUCE_TIER_COLLISION_COMPLET: 0.048,
  //REDUCE_COMMERCIAL: 0.2,
  REDUCE_COMMERCIAL: 0.35,
  MTT_ACCESSOIRE: 5000,
  MTT_CEDEAO: 1000,
  //VOL_ACCESSOIRE: 15000,
  VOL_ACCESSOIRE: 12000,
  //MTT_AVANCE_RECOURS: 15000,
  MTT_AVANCE_RECOURS: 12000,
};
