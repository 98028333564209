export class User {

  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  birthday: Date;
  shopId: string;

  // tslint:disable-next-line:variable-name
  has_payqin_account: boolean;
  static firstname: any;
  static lastname: any;
  static email: any;
  static phone: any;
  static birthday: any;
  static customerNumber: any;
  static updated_at: any;
  static created_at: any;
  static id: any;

  constructor(name: string, prenom: string, email: string, phone: string, birthday: Date, shopId: string, hasAccount: boolean)  {
    this.firstname = name;
    this.lastname = prenom;
    this.email = email;
    this.phone = phone;
    this.birthday = birthday;
    this.shopId = shopId;
    this.has_payqin_account = hasAccount;
  }

}
