export const TARIFSANTE = [

  {
    libelle: 'ACACIA',
    prix: 350000,
    taux: 80
  },
  {
    libelle: 'IROKO',
    prix: 600000,
    taux: 80
  },
  {
    libelle: 'ACAJOU',
    prix: 800000,
    taux: 80
  }

];
