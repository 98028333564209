import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Router} from '@angular/router';

import { LoadingService } from '../../_services/loading.service';
import { UserService } from '../../_services/user.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-connexion',
  templateUrl: './connexion.component.html',
  styleUrls: ['./connexion.component.scss']
})

export class ConnexionComponent implements OnInit {

  env = environment;
  loginForm: FormGroup;
  isSubmitted = false;
  emailIncorrect = false;
  isConnected: boolean;

  constructor(private router: Router, private formBuilder: FormBuilder, private loadingService: LoadingService,
              private userService: UserService) { }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  validationLogin() {

    if (this.loginForm.invalid) {
      return;
    } else {

      this.isSubmitted = true;
      this.userService.getAuthOnApi(this.loginForm.value.email, this.loginForm.value.password).subscribe(
        (data) => {

          // console.log('afficher data'); console.log(data['error']);
          if (data['success']) {

            sessionStorage.setItem('firstname', data['user'][0].firstname);
            sessionStorage.setItem('lastname', data['user'][0].lastname);
            sessionStorage.setItem('email', data['user'][0].email);
            sessionStorage.setItem('phone', data['user'][0].phone);
            sessionStorage.setItem('birthday', data['user'][0].birthday);
            sessionStorage.setItem('customerNumber', data['user'][0].customerNumber);
            sessionStorage.setItem('shopId', data['user'][0].shopId);
            sessionStorage.setItem('has_payqin_account', this.userService.convertBooleanToString(data['user'][0].has_payqin_account));
            sessionStorage.setItem('created_at', data['user'][0].created_at);
            sessionStorage.setItem('updated_at', data['user'][0].updated_at);
            sessionStorage.setItem('isAuth', 'true');
            this.env.isAuth = true;
            window.location.href = '/';
          }
          // Password Incorrect
          if (data['error']) {
              this.emailIncorrect = true;
              this.env.isAuth = true;
          }

        },
        (error) => {
          console.log(error + 'erreur actuelle');
        }
      );

    }

  }

  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
    this.isConnected = this.userService.isUserAuth();
  }

  /*login(f: { value: any; }){
    parent.postMessage(f.value,location.origin);
    window.close();
    }*/

}
