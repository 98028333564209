export const detailsCouverture = {
  respoCivile: 0,
  defenseRecours: 0,
  individuelConducteur: 0,
  incendie: 0,
  volMainsArmees: 0,
  volVehicule: 0,
  volAccessoire: 0,
  brisDeGlaces: 0,
  avanceRecours: 0,
  dommagColTiersID: 0,
  dommagColComplet: 0,
  respoCivileReduite: 0,
  primeBrut: 0,
  primeNet: 0,
  primeNetTot: 0,
  taxe: 0,
  totalApayer: 0,
  fondGarantie: 0,
  prixConvertDureeTotalAPayer: 0,
  prixVenal: 0
};
