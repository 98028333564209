import { Tarifs } from './tarifs';
/* NB:
  TYPE destination = d1->AFRIQUE DE L'OUEST / d2->SHENGHEN ET AFRIQUE DU NORD / d3->MONDE ENTIER
  TYPE age = bebe ->[0 - 1 AN] / enfant->[2 - 11 ANS] / adulte->[12 - 80 ANS]
*/
export const MESTARIFS: Tarifs[] = [
  // BEBE AFRIQ DE L'OUEST
  {
    destination: 'd1',
    age: 'bebe',
    duree: 7,
    prix: 2500
  },
  {
    destination: 'd1',
    age: 'bebe',
    duree: 10,
    prix: 3000
  },
  {
    destination: 'd1',
    age: 'bebe',
    duree: 15,
    prix: 3750
  },
  {
    destination: 'd1',
    age: 'bebe',
    duree: 21,
    prix: 5125
  },
  {
    destination: 'd1',
    age: 'bebe',
    duree: 30,
    prix: 6250
  },
  {
    destination: 'd1',
    age: 'bebe',
    duree: 45,
    prix: 7625
  },
  {
    destination: 'd1',
    age: 'bebe',
    duree: 60,
    prix: 8875
  },
  {
    destination: 'd1',
    age: 'bebe',
    duree: 91,
    prix: 5125
  },
  {
    destination: 'd1',
    age: 'bebe',
    duree: 180,
    prix: 15500
  },
  {
    destination: 'd1',
    age: 'bebe',
    duree: 365,
    prix: 17500
  },
  // ENFANT AFRIQ DE L'OUEST
  {
    destination: 'd1',
    age: 'enfant',
    duree: 7,
    prix: 7500
  },
  {
    destination: 'd1',
    age: 'enfant',
    duree: 10,
    prix: 9000
  },
  {
    destination: 'd1',
    age: 'enfant',
    duree: 15,
    prix: 11250
  },
  {
    destination: 'd1',
    age: 'enfant',
    duree: 21,
    prix: 15375
  },
  {
    destination: 'd1',
    age: 'enfant',
    duree: 30,
    prix: 18750
  },
  {
    destination: 'd1',
    age: 'enfant',
    duree: 45,
    prix: 22875
  },
  {
    destination: 'd1',
    age: 'enfant',
    duree: 60,
    prix: 26625
  },
  {
    destination: 'd1',
    age: 'enfant',
    duree: 91,
    prix: 32625
  },
  {
    destination: 'd1',
    age: 'enfant',
    duree: 180,
    prix: 46500
  },
  {
    destination: 'd1',
    age: 'enfant',
    duree: 365,
    prix: 52500
  },
  // ADULTE AFRIQ DE L'OUEST
  {
    destination: 'd1',
    age: 'adulte',
    duree: 7,
    prix: 10000
  },
  {
    destination: 'd1',
    age: 'adulte',
    duree: 10,
    prix: 12000
  },
  {
    destination: 'd1',
    age: 'adulte',
    duree: 15,
    prix: 15000
  },
  {
    destination: 'd1',
    age: 'adulte',
    duree: 21,
    prix: 20500
  },
  {
    destination: 'd1',
    age: 'adulte',
    duree: 30,
    prix: 25000
  },
  {
    destination: 'd1',
    age: 'adulte',
    duree: 45,
    prix: 30500
  },
  {
    destination: 'd1',
    age: 'adulte',
    duree: 60,
    prix: 35500
  },
  {
    destination: 'd1',
    age: 'adulte',
    duree: 91,
    prix: 43500
  },
  {
    destination: 'd1',
    age: 'adulte',
    duree: 180,
    prix: 62000
  },
  {
    destination: 'd1',
    age: 'adulte',
    duree: 365,
    prix: 70000
  },
  // BEBE SHEGHEN ET AFRIQUE DU NORD
  {
    destination: 'd2',
    age: 'bebe',
    duree: 7,
    prix: 2750
  },
  {
    destination: 'd2',
    age: 'bebe',
    duree: 10,
    prix: 3375
  },
  {
    destination: 'd2',
    age: 'bebe',
    duree: 15,
    prix: 4125
  },
  {
    destination: 'd2',
    age: 'bebe',
    duree: 21,
    prix: 5750
  },
  {
    destination: 'd2',
    age: 'bebe',
    duree: 30,
    prix: 6875
  },
  {
    destination: 'd2',
    age: 'bebe',
    duree: 45,
    prix: 8500
  },
  {
    destination: 'd2',
    age: 'bebe',
    duree: 60,
    prix: 9875
  },
  {
    destination: 'd2',
    age: 'bebe',
    duree: 91,
    prix: 12125
  },
  {
    destination: 'd2',
    age: 'bebe',
    duree: 180,
    prix: 17250
  },
  {
    destination: 'd2',
    age: 'bebe',
    duree: 365,
    prix: 19500
  },
  // ENFANT SHEGHEN ET AFRIQUE DU NORD
  {
    destination: 'd2',
    age: 'enfant',
    duree: 7,
    prix: 8250
  },
  {
    destination: 'd2',
    age: 'enfant',
    duree: 10,
    prix: 10125
  },
  {
    destination: 'd2',
    age: 'enfant',
    duree: 15,
    prix: 12375
  },
  {
    destination: 'd2',
    age: 'enfant',
    duree: 21,
    prix: 17250
  },
  {
    destination: 'd2',
    age: 'enfant',
    duree: 30,
    prix: 20625
  },
  {
    destination: 'd2',
    age: 'enfant',
    duree: 45,
    prix: 25500
  },
  {
    destination: 'd2',
    age: 'enfant',
    duree: 60,
    prix: 29625
  },
  {
    destination: 'd2',
    age: 'enfant',
    duree: 91,
    prix: 36375
  },
  {
    destination: 'd2',
    age: 'enfant',
    duree: 180,
    prix: 51750
  },
  {
    destination: 'd2',
    age: 'enfant',
    duree: 365,
    prix: 58500
  },
  // ADULTE SHEGHEN ET AFRIQUE DU NORD
  {
    destination: 'd2',
    age: 'adulte',
    duree: 7,
    prix: 11000
  },
  {
    destination: 'd2',
    age: 'adulte',
    duree: 10,
    prix: 13500
  },
  {
    destination: 'd2',
    age: 'adulte',
    duree: 15,
    prix: 16500
  },
  {
    destination: 'd2',
    age: 'adulte',
    duree: 21,
    prix: 23000
  },
  {
    destination: 'd2',
    age: 'adulte',
    duree: 30,
    prix: 27500
  },
  {
    destination: 'd2',
    age: 'adulte',
    duree: 45,
    prix: 34000
  },
  {
    destination: 'd2',
    age: 'adulte',
    duree: 60,
    prix: 39500
  },
  {
    destination: 'd2',
    age: 'adulte',
    duree: 91,
    prix: 48500
  },
  {
    destination: 'd2',
    age: 'adulte',
    duree: 180,
    prix: 69000
  },
  {
    destination: 'd2',
    age: 'adulte',
    duree: 365,
    prix: 78000
  },
  // BEBE MONDE ENTIER
  {
    destination: 'd3',
    age: 'bebe',
    duree: 7,
    prix: 3375
  },
  {
    destination: 'd3',
    age: 'bebe',
    duree: 10,
    prix: 4250
  },
  {
    destination: 'd3',
    age: 'bebe',
    duree: 15,
    prix: 5250
  },
  {
    destination: 'd3',
    age: 'bebe',
    duree: 21,
    prix: 6750
  },
  {
    destination: 'd3',
    age: 'bebe',
    duree: 30,
    prix: 7625
  },
  {
    destination: 'd3',
    age: 'bebe',
    duree: 45,
    prix: 10500
  },
  {
    destination: 'd3',
    age: 'bebe',
    duree: 60,
    prix: 13500
  },
  {
    destination: 'd3',
    age: 'bebe',
    duree: 91,
    prix: 17000
  },
  {
    destination: 'd3',
    age: 'bebe',
    duree: 180,
    prix: 20750
  },
  {
    destination: 'd3',
    age: 'bebe',
    duree: 365,
    prix: 28000
  },
  // ENFANT MONDE ENTIER
  {
    destination: 'd3',
    age: 'enfant',
    duree: 7,
    prix: 10125
  },
  {
    destination: 'd3',
    age: 'enfant',
    duree: 10,
    prix: 12750
  },
  {
    destination: 'd3',
    age: 'enfant',
    duree: 15,
    prix: 15750
  },
  {
    destination: 'd3',
    age: 'enfant',
    duree: 21,
    prix: 20250
  },
  {
    destination: 'd3',
    age: 'enfant',
    duree: 30,
    prix: 22875
  },
  {
    destination: 'd3',
    age: 'enfant',
    duree: 45,
    prix: 31500
  },
  {
    destination: 'd3',
    age: 'enfant',
    duree: 60,
    prix: 40500
  },
  {
    destination: 'd3',
    age: 'enfant',
    duree: 91,
    prix: 51000
  },
  {
    destination: 'd3',
    age: 'enfant',
    duree: 180,
    prix: 62250
  },
  {
    destination: 'd3',
    age: 'enfant',
    duree: 365,
    prix: 84000
  },
  // ADULTE MONDE ENTIER
  {
    destination: 'd3',
    age: 'adulte',
    duree: 7,
    prix: 13500
  },
  {
    destination: 'd3',
    age: 'adulte',
    duree: 10,
    prix: 17500
  },
  {
    destination: 'd3',
    age: 'adulte',
    duree: 15,
    prix: 21000
  },
  {
    destination: 'd3',
    age: 'adulte',
    duree: 21,
    prix: 27000
  },
  {
    destination: 'd3',
    age: 'adulte',
    duree: 30,
    prix: 30500
  },
  {
    destination: 'd3',
    age: 'adulte',
    duree: 45,
    prix: 42000
  },
  {
    destination: 'd3',
    age: 'adulte',
    duree: 60,
    prix: 54000
  },
  {
    destination: 'd3',
    age: 'adulte',
    duree: 91,
    prix: 68000
  },
  {
    destination: 'd3',
    age: 'adulte',
    duree: 180,
    prix: 83000
  },
  {
    destination: 'd3',
    age: 'adulte',
    duree: 365,
    prix: 112000
  }

];
