import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Router} from '@angular/router';

import { UserService } from './_services/user.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'payqin';
  env = environment;
  isConnected: boolean;
  loginForm: FormGroup;
  isSubmitted = false;
  emailIncorrect = false;
  isAuth: boolean;

  constructor(private userService: UserService, private router: Router, private formBuilder: FormBuilder) {}

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  validationLogin() {

    this.isSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    } else {

      this.userService.getAuthOnApi(this.loginForm.value.email, this.loginForm.value.password).subscribe(

        (data) => {

          // console.log('afficher data'); console.log(data['error']);
          if (data['success']) {

            sessionStorage.setItem('firstname', data['user'][0].firstname);
            sessionStorage.setItem('lastname', data['user'][0].lastname);
            sessionStorage.setItem('email', data['user'][0].email);
            sessionStorage.setItem('phone', data['user'][0].phone);
            sessionStorage.setItem('birthday', data['user'][0].birthday);
            sessionStorage.setItem('customerNumber', data['user'][0].customerNumber);
            sessionStorage.setItem('shopId', data['user'][0].shopId);
            sessionStorage.setItem('has_payqin_account', this.userService.convertBooleanToString(data['user'][0].has_payqin_account));
            sessionStorage.setItem('created_at', data['user'][0].created_at);
            sessionStorage.setItem('updated_at', data['user'][0].updated_at);
            sessionStorage.setItem('isAuth', 'true');
            this.env.isAuth = true;
            this.isConnected = this.userService.isUserAuth();
          }
          // Password Incorrect
          if (data['error']) {
            this.emailIncorrect = true;
            this.isConnected = this.userService.isUserAuth();
          }
        },
        (error) => {
          console.log(error + 'erreur requte');
        }
      );

    }
  }

  onClick() {
    console.log('lolo poil');
    this.env.isAuth = true;
    console.log( this.env.isAuth = true );
  }

  deconnexion() {
    this.userService.deconnexion();
    window.location.href = '/';
  }

  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
    this.isConnected = this.userService.isUserAuth();

    this.isAuth = this.userService.isUserAuth();
  }

}

