import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators } from '@angular/forms';

import { LoadingService } from '../../_services/loading.service';
import { UserService } from '../../_services/user.service';
import { JwtService } from '../../_services/services/jwt.service';
import { MustMatch } from '../../_helpers/must-match.validator';
import { error } from '@angular/compiler/src/util';
// import { MyErrorStateMatcher } from 'src/app/_models/my-error-state-matcher';

export class User {
  name: String | undefined;
  email: String | undefined;
}

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  userInfos: any;
  profilForm: FormGroup;
  resetPasswordForm: FormGroup;
  accountBusiness: string;
  isSubmitted = false;
  isSubmittedPass = false;
  successActionOK = false;
  errorActionOK = false;
  succesProfilOK = false;
  msgError = '';
  // matcher = new MyErrorStateMatcher();

  user: User | undefined;

  constructor(private loadingService: LoadingService, 
    private userService: UserService, 
    private jwtService: JwtService,  
    private formBuilder: FormBuilder) { 
      this.jwtService.profile().subscribe((res:any) => {
        this.user = res;
      })
    }

  // convenience getter for easy access to form fields
  get f() { return this.profilForm.controls; }
  get p() { return this.resetPasswordForm.controls; }

  passwordChange(formGroup: FormGroup) {
    const { value: password } = formGroup.get('password');
    const { value: password02 } = formGroup.get('password02');
    return password === password02 ? null : { passwordNotMatch: true };
  }

  validationProfilForm() {

    this.isSubmitted = true;
    if (this.profilForm.invalid) {
      return;
    } else {

      const firstName = this.profilForm.value.firstName;
      const birthday = this.profilForm.value.birthday;
      const lastName = this.profilForm.value.lastName;
      const email = this.profilForm.value.email;
      const phone = this.profilForm.value.phone;
      // Mise à jour du profil
      this.userService.updateUserProfil(firstName, lastName, email, phone, birthday).subscribe(
        (data) => {
          if (data['success'] === true) {
            this.succesProfilOK = true;
          }
        },
        (error) => {
          console.log( error );
        }
      );
    }
  }
  validationResetPassword() {

    this.isSubmittedPass = true;
    if (this.resetPasswordForm.invalid) {
      return;
    } else {

      const email = this.resetPasswordForm.value.email;
      const oldPassword = this.resetPasswordForm.value.oldPassword;
      const password = this.resetPasswordForm.value.password;
      this.userService.changeUserPassword(email, oldPassword, password).subscribe(
        (data) => {

          if (data['success'] === true) {
            this.successActionOK = true;
            this.resetPasswordForm.reset();
          }
          if (data['success'] === false) {

            if (data['message']){
              this.errorActionOK = true;
              this.msgError = 'Votre ancien mot de passe est incorrect';
            }
            if (data['error']) {
              this.errorActionOK = true;
              this.msgError = 'Cette adresse Email n\est pas reconnue';
            }
          }
        },
        (error) => {
          console.log( error );
        }
      );
    }
  }
  ngOnInit() {

    this.userInfos = this.userService.getUserAllInfos();
    if (this.userService.getUserAllInfos().has_payqin_account === true) {
      this.accountBusiness = 'OUI';
    } else {
      this.accountBusiness = 'NON';
    }
    // Form profile
    this.profilForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      birthday: ['', Validators.required],
      created_at: ['', Validators.required],
      updated_at: ['', Validators.required],
      phone: ['', Validators.required],
      has_payqin_account: ['', Validators.required],
      customerNumber: ['', Validators.required],
      shopId: ['', Validators.required]
    });
    // Initalisation de la form Profil
    this.profilForm.controls.email.setValue(this.userInfos.email);
    this.profilForm.controls.firstName.setValue(this.userInfos.firstName);
    this.profilForm.controls.lastName.setValue(this.userInfos.lastName);
    this.profilForm.controls.birthday.setValue(this.userInfos.birthday);
    this.profilForm.controls.created_at.setValue(this.userInfos.created_at);
    this.profilForm.controls.updated_at.setValue(this.userInfos.updated_at);
    this.profilForm.controls.phone.setValue(this.userInfos.phone);
    this.profilForm.controls.has_payqin_account.setValue(this.userService.translateBoolean(this.userInfos.has_payqin_account));
    this.profilForm.controls.customerNumber.setValue(this.userInfos.customerNumber);
    this.profilForm.controls.shopId.setValue(this.userInfos.shopId);
    // Initialisation de la form password reset
    this.resetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      oldPassword: ['', Validators.required],
      password: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
      password02: ['', Validators.compose([Validators.required, Validators.minLength(6)])]
    }, { validator: MustMatch('password', 'password02') });

  }

}
