import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// import { PhoneMaskDirective } from '../../_directives/phone-mask.directive';
import { LoadingService } from '../../_services/loading.service';
import { ChoixDateValidator } from '../../_validators/chdate.validator';
import { UserService } from '../../_services/user.service';
import { User } from '../../_models/user';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  createFormGroup: FormGroup;
  isSubmitted = false;
  userCurrent: any;
  isAuth: boolean;

  constructor(private formBuilder: FormBuilder, 
    private loadingService: LoadingService, 
    private userService: UserService) { }

  deconnexion() {
    this.userService.deconnexion();
    window.location.href = '/';
  }

  ngOnInit() {

    // console.log( this.isAuth );
    this.isAuth = this.userService.isUserAuth();
  }

}
