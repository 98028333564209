/* NB:
  TYPE destination = d1->AFRIQUE DE L'OUEST / d2->SHENGHEN ET AFRIQUE DU NORD / d3->MONDE ENTIER
  TYPE age = bebe ->[0 - 1 AN] / enfant->[2 - 11 ANS] / adulte->[12 - 80 ANS]
*/
export const MESTARIFSCOMAR = [

  // AFRIQUE ET ASIE POUR LAUTRE CEST AFRIQUE DE LOUEST
  {
    destination: 'd1',
    duree: 7,
    prix: 9556
  },
  {
    destination: 'd1',
    duree: 15,
    prix: 12602
  },
  {
    destination: 'd1',
    duree: 30,
    prix: 18690
  },
  {
    destination: 'd1',
    duree: 60,
    prix: 26302
  },
  {
    destination: 'd1',
    duree: 91,
    prix: 32390
  },
  {
    destination: 'd1',
    duree: 180,
    prix: 35436
  },
  {
    destination: 'd1',
    duree: 365,
    prix: 44569
  },

  // EUROPE ENTIER LAUTRE SHENGHEN ET AFRIQUE DU NORD
  {
    destination: 'd2',
    duree: 7,
    prix: 12979
  },
  {
    destination: 'd2',
    duree: 15,
    prix: 17882
  },
  {
    destination: 'd2',
    duree: 30,
    prix: 27692
  },
  {
    destination: 'd2',
    duree: 60,
    prix: 41179
  },
  {
    destination: 'd2',
    duree: 91,
    prix: 52216
  },
  {
    destination: 'd2',
    duree: 180,
    prix: 55894
  },
  {
    destination: 'd2',
    duree: 365,
    prix: 70607
  },

  // MONDE ENTIER LAUTRE MONDE ENTIER
  {
    destination: 'd3',
    duree: 7,
    prix: 14206
  },
  {
    destination: 'd3',
    duree: 15,
    prix: 20013
  },
  {
    destination: 'd3',
    duree: 30,
    prix: 36859
  },
  {
    destination: 'd3',
    duree: 60,
    prix: 53558
  },
  {
    destination: 'd3',
    duree: 91,
    prix: 68739
  },
  {
    destination: 'd3',
    duree: 180,
    prix: 83686
  },
  {
    destination: 'd3',
    duree: 365,
    prix: 113523
  },

];
