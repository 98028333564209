export const ConstGarantiesSIDAM = {
  DEFENSE_RECOURS: 7950,
  INDIVIDUEL_CONDUCTEUR: 12000,
  REDUCE_PROF_RELIGIEUX: 10,
  REDUCE_PROF_ARTISAN: 10,
  REDUCE_PROF_TRAVAILLEUR: 5,
  REDUCE_PROF_AUTRE: 0,
  REDUCE_INCENDIE: 0.0065,
  REDUCE_VOL_VOL_MAINS_LEVEE: 0.006,
  REDUCE_BRIS_GLACE: 0.004,
  REDUCE_TIER_COLLISION_ID: 0.02, // collision tiers identifiable ou tierce collision
  REDUCE_TIER_COLLISION_COMPLET: 0.033, // tout risque
  REDUCE_COMMERCIAL: 0.3,
  MTT_ACCESSOIRE: 5000,
  MTT_CEDEAO: 1000,
  VOL_ACCESSOIRE: 15000,
  MTT_AVANCE_RECOURS: 15000,
};
