import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-comprendre-mon-contrat-assurance',
  templateUrl: './comprendre-mon-contrat-assurance.component.html',
  styleUrls: ['./comprendre-mon-contrat-assurance.component.scss']
})
export class ComprendreMonContratAssuranceComponent implements OnInit {

  constructor() {}

  ngOnInit() {
  }

}
