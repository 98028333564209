import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export class User {
  name: String | undefined;
  email: String | undefined;
  password: String | undefined;
  password_confirmation: String | undefined
}

@Injectable({
  providedIn: 'root'
})

export class JwtService {

  constructor(private http: HttpClient) { }

  signUp(user: User): Observable<any> {
    return this.http.post('http://127.0.0.1:8000/api/auth/signup', user);
  }

  logIn(user: User): Observable<any> {
    return this.http.post<any>('http://127.0.0.1:8000/api/auth/signin', user);
  }

  profile(): Observable<any> {
    return this.http.get('http://127.0.0.1:8000/api/auth/user');
  }

  getUserAllInfos(): {  fullName: string; firstName: string; lastName: string; email: string; birthday: string; created_at: string;
    updated_at: string; phone: string; has_payqin_account: boolean; customerNumber: string; shopId: string } {

    return {
      fullName: sessionStorage.getItem('firstname') + ' ' + sessionStorage.getItem('lastname'),
      firstName: sessionStorage.getItem('firstname'),
      lastName: sessionStorage.getItem('lastname'),
      email: sessionStorage.getItem('email'),
      birthday: sessionStorage.getItem('birthday'),
      created_at: sessionStorage.getItem('created_at'),
      updated_at: sessionStorage.getItem('updated_at'),
      phone: sessionStorage.getItem('phone'),
      has_payqin_account: this.convertStringToBoolean(sessionStorage.getItem('has_payqin_account')),
      customerNumber: sessionStorage.getItem('customerNumber'),
      shopId: sessionStorage.getItem('shopId'),
    };
  }

  convertStringToBoolean(value: string): boolean {

    switch (value) {
      case 'false':
        return false;
      case 'true':
        return true;
    }
  }

}