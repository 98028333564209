import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SanteService {

  calculSupplementaire(nbEnfant: number) {

    const prixUnitaireSupp = 61000;
    // une famille cest les parents + 3
    if (nbEnfant <= 3) {
      return 0;
    } else {
      const result = nbEnfant - 3;
      return (prixUnitaireSupp * result);
    }
  }

  constructor() { }
}
