export const TARIFSANTE01 = [

  {
    libelle: 'ACACIA',
    prix: 260000,
    taux: 80
  },
  {
    libelle: 'IROKO',
    prix: 540000,
    taux: 80
  },
  {
    libelle: 'ACAJOU',
    prix: 730000,
    taux: 80
  },
  {
    libelle: 'EBENE',
    prix: 980000,
    taux: 100
  },

]
