import { FormControl } from '@angular/forms';
export class ChoixDateValidator {
  static validCdate(fc: FormControl) {

    if ( Date.now() <= new Date(fc.value).getTime() ) {
      return ({validCdate: true});
    } else {
      return (null);
    }

  }
}
