import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SanteService } from '../../_services/sante.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LoadingService } from '../../_services/loading.service';

import { TARIFSANTE } from '../../_shared/tarifSante';
import { TARIFSANTE01 } from '../../_shared/tarifSante01';
import { TARIFSANTE02 } from '../../_shared/tarifSante02';
import { AssuOffreSante } from '../../_shared/assurOffreSante';
import { environment } from '../../../environments/environment';

interface IShwDescription {
  title: boolean;
}

@Component({
  selector: 'app-sante',
  templateUrl: './sante.component.html',
  styleUrls: ['./sante.component.scss']
})

export class SanteComponent implements OnInit {

  env = environment;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  qestionFormGroup: FormGroup;
  isSubmitted = false;
  isSubmitted2 = false;
  isSubmitted3 = false;
  isSubmittedQ = false;
  isSubmittedPrice = false;
  showFormulAcacia = false;
  showFormulIroko = false;
  showFormulAcajou = false;
  showFormulEbene = false;
  offreAssurSante: AssuOffreSante[];
  choiceQ1 = true;
  // Fields Show description textarea
  showDescript2 = true;
  showDescript3 = true;
  showDescript4 = true;
  showDescript5 = true;
  showDescript6 = true;
  showDescript7 = true;
  showDescript8 = true;
  showDescript9 = true;
  showDescript10 = true;
  showDescript11 = true;
  showDescript12 = true;
  showDescript13 = true;
  showDescript14 = true;
  showDescript15 = true;
  showDescript16 = true;
  showDescript17 = true;
  showDescript18 = true;
  showDescript19 = true;

  formuleAcacia: any;
  formuleIroko: any;
  formuleAcajou: any;
  formuleEbene: any;

  mesTarifs = [];
  mesTarifs01 = [];
  mesTarifs02 = [];

  assurForm: FormGroup;
  errorSoumission: boolean;
  resultOffre: any;
  prixOffre = 0;
  showPrice: boolean;
  showErrPrice: boolean;

  deviceInfo = null;

  constructor(private formBuilder: FormBuilder, private router: Router, private loadingService: LoadingService,
              private santeService: SanteService, private deviceService: DeviceDetectorService) { }

  // convenience getter for easy access to form fields
  get s() { return this.secondFormGroup.controls; }
  get f() { return this.firstFormGroup.controls; }
  get q() { return this.qestionFormGroup.controls; }
  get qf() { return this.qestionFormGroup; }

  validationSecondForm() {

    // const val = this.secondFormGroup.value;
    this.isSubmitted2 = true;
    if (this.secondFormGroup.invalid)  {
      console.log(this.secondFormGroup);
      return;
    } else {

      // Recherche des price
      const val = this.secondFormGroup.value;
      this.mesTarifs.forEach(element => {
        if (element.libelle === val.formule) {
          this.offreAssurSante[0].prix = element.prix + this.santeService.calculSupplementaire(val.nbEnfant);
          console.log(this.mesTarifs);
          return;
        }
      });

      // Recherche des prix couverture 100%
      /*var formuleEbene = 0;
      this.mesTarifs.forEach(element => {
        if (element.libelle === val.formule) {
          this.offreAssurSante[0].prix = element.prix + this.santeService.calculSupplementaire(val.nbEnfant);
          console.log(this.offreAssurSante[0].prix);
          return;
        }
      });*/
      
    }
    
  }

  validationFirstForm() {
    this.isSubmitted3 = true;
    if (this.firstFormGroup.invalid) {
      console.log(this.firstFormGroup);
      return;
    } else {
      console.log('FORMULAIRE VALIDE PREMIERE ETAPE');
      console.log(this.firstFormGroup);
    }
  }
  
  onChangeFormule(event: { target: { value: any; }; }) {

    switch (event.target.value) {

      case 'ACACIA':
      this.showFormulAcacia = true;
      this.showFormulIroko = false;
      this.showFormulAcajou = false;
      this.showFormulEbene = false;
      break;
      case 'IROKO':
      this.showFormulAcacia = false;
      this.showFormulIroko = true;
      this.showFormulAcajou = false;
      this.showFormulEbene = false;
      break;
      case 'ACAJOU':
      this.showFormulAcacia = false;
      this.showFormulIroko = false;
      this.showFormulAcajou = true;
      this.showFormulEbene = false;
      break;
      case 'EBENE':
      this.showFormulAcacia = false;
      this.showFormulIroko = false;
      this.showFormulAcajou = false;
      this.showFormulEbene = true;
      break;

      default:
      this.showFormulAcacia = false;
      this.showFormulIroko = false;
      this.showFormulAcajou = false;
      this.showFormulEbene = false;
      break;

    }
  }
  onChangeQ1Sexe() {

    const val = this.qestionFormGroup.value;
    if (val.Q1 === 'homme') {
      this.qestionFormGroup.controls.Q15.setValue('NON');
      this.qestionFormGroup.controls.Q16.setValue('NON');
      this.qestionFormGroup.controls.Q17.setValue('NON');
      this.qestionFormGroup.controls.Q18.setValue('NON');
      this.choiceQ1 = false;
    } else if (val.Q1 === 'femme'){
      this.choiceQ1 = true;
      this.qestionFormGroup.reset();
      this.qestionFormGroup.controls.Q1.setValue('femme');
    }
  }
  validationFormQuestion() {

    const val = this.qestionFormGroup.value;
    this.isSubmittedQ = true;
    // Formulaire invalid
    if (this.qestionFormGroup.invalid)  {
      console.log(this.qestionFormGroup);
      return;
    } else {
      // Permission show prix
      console.log('FORMULAIRE VALIDE');
      console.log(this.qestionFormGroup);
    }

  }
  onChecked(val1: string, val2: any) {

    if (val1 === 'Q2') {
      switch (val2) {

        case 0:
          this.showDescript2 = true;
          break;
        case 1:
          this.showDescript2 = false;
          break;
      }
    } else if (val1 === 'Q3') {
      switch (val2) {

        case 0:
          this.showDescript3 = true;
          break;
        case 1:
          this.showDescript3 = false;
          break;
      }
    } else if (val1 === 'Q4') {
      switch(val2) {
        case 0:
          this.showDescript4 = true;
          break;
        case 1:
            this.showDescript4 = false;
          break;
      }

    } else if (val1 === 'Q5') {
      switch (val2) {
        case 0:
          this.showDescript5 = true;
          break;
        case 1:
          this.showDescript5 = false;
          break;
      }
    } else if(val1 === 'Q6') {
      switch(val2) {
        case 0:
          this.showDescript6 = true;
          break;
        case 1:
          this.showDescript6 = false;
          break;
      }
    } else if(val1 === 'Q7') {
      switch(val2) {
        case 0:
          this.showDescript7 = true;
          break;
        case 1:
          this.showDescript7 = false;
          break;
      }
    } else if(val1 === 'Q8') {
      switch(val2) {
        case 0:
          this.showDescript8 = true;
          break;
        case 1:
          this.showDescript8 = false;
          break;
      }
    } else if(val1 === 'Q9') {
      switch(val2) {
        case 0:
          this.showDescript9 = true;
          break;
        case 1:
          this.showDescript9 = false;
          break;
      }
    } else if(val1==='Q10') {
      switch(val2) {
        case 0:
          this.showDescript10 = true;
          break;
        case 1:
          this.showDescript10 = false;
          break;
      }
    } else if(val1==='Q11') {
      switch(val2) {
        case 0:
          this.showDescript11 = true;
          break;
        case 1:
          this.showDescript11 = false;
          break;
      }
    } else if(val1==='Q12') {
      switch(val2) {
        case 0:
          this.showDescript12 = true;
          break;
        case 1:
          this.showDescript12 = false;
          break;
      }
    } else if(val1==='Q13') {
      switch(val2) {
        case 0:
          this.showDescript13 = true;
          break;
        case 1:
          this.showDescript13 = false;
          break;
      }
    } else if(val1==='Q14') {
      switch(val2) {
        case 0:
          this.showDescript14 = true;
          break;
        case 1:
          this.showDescript14 = false;
          break;
      }
    } else if(val1==='Q15') {
      switch(val2) {
        case 0:
          this.showDescript15 = true;
          break;
        case 1:
          this.showDescript15 = false;
          break;
      }
    } else if(val1==='Q16') {
      switch(val2) {
        case 0:
          this.showDescript16 = true;
          break;
        case 1:
          this.showDescript16 = false;
          break;
      }
    } else if(val1==='Q17') {
      switch(val2) {
        case 0:
          this.showDescript17 = true;
          break;
        case 1:
          this.showDescript17 = false;
          break;
      }
    } else if(val1==='Q18') {
      switch(val2) {
        case 0:
          this.showDescript18 = true;
          break;
        case 1:
          this.showDescript18 = false;
          break;
      }
    } else if(val1==='Q19') {
      switch(val2) {
        case 0:
          this.showDescript19 = true;
          break;
        case 1:
          this.showDescript19 = false;
          break;
      }
    }
  }
  voirTarif() {

    const val = this.assurForm.value;
    if (this.assurForm.invalid) {

      this.prixOffre = 0;
      this.showErrPrice = true;
      return;
    } else {

      this.loadingService.chargement();
      this.mesTarifs.forEach(element => {
        if (element.libelle === val.formule) {
          this.resultOffre = element;
          this.prixOffre = this.resultOffre.prix + this.santeService.calculSupplementaire(val.nbEnfts);
        }
      });
      this.showPrice = false;
      this.showErrPrice = false;
    }
  }
  validationForm() {

    const val = this.assurForm.value;
    this.isSubmitted = true;
    // Formulaire invalid
    if (this.assurForm.invalid)  {
      return;
    } else {
      // Permission show prix
      console.log('formulaire valid3');
    }
    console.log(this.assurForm.value);

  }

  souscription() {
    this.isSubmittedPrice = true;
  }

  isMobileDevice() {
    this.deviceService.isMobile();
  }

  ngOnInit() {

    this.secondFormGroup  =  this.formBuilder.group({
      //formule: ['', Validators.required],
      nbEnfts: ['', [Validators.min(0), Validators.required]],  // Nombre des enfts
      taux: ['', Validators.required],
      nbFamille: ['']
    });
    this.firstFormGroup = this.formBuilder.group({
      social: ['', Validators.required]
    });
    this.thirdFormGroup = this.formBuilder.group({
      
    });
    this.qestionFormGroup  =  this.formBuilder.group({
      Q1: ['', Validators.required],
      Q2: ['', Validators.required],
      Qdes2: [''],
      Q3: ['', Validators.required],
      Qdes3: [''],
      Q4: ['', Validators.required],
      Qdes4: [''],
      Q5: ['', Validators.required],
      Qdes5: [''],
      Q6: ['', Validators.required],
      Qdes6: [''],
      Q7: ['', Validators.required],
      Qdes7: [''],
      Q8: ['', Validators.required],
      Qdes8: [''],
      Q9: ['', Validators.required],
      Qdes9: [''],
      Q10: ['', Validators.required],
      Qdes10: [''],
      Q11: ['', Validators.required],
      Qdes11: [''],
      Q12: ['', Validators.required],
      Qdes12: [''],
      Q13: ['', Validators.required],
      Qdes13: [''],
      Q14: ['', Validators.required],
      Qdes14: [''],
      Q15: ['', Validators.required],
      Qdes15: [''],
      Q16: ['', Validators.required],
      Qdes16: [''],
      Q17: ['', Validators.required],
      Qdes17: [''],
      Q18: ['', Validators.required],
      Qdes18: [''],
      Q19: ['', Validators.required],
      Qdes19: ['']
    });

    this.offreAssurSante = [
      {
        libelle: 'VITALIS',
        prix: 0,
        taux: 0
      },
      {
        libelle: 'NSIA ASSUR',
        prix: 0,
        taux: 0
      }
    ];
    this.isSubmitted = false;
    this.isSubmitted2 = false;

    this.mesTarifs = TARIFSANTE;
    this.mesTarifs01 = TARIFSANTE01;
    this.mesTarifs02 = TARIFSANTE02;

    this.errorSoumission = false;

    this.showPrice = true;
    this.showErrPrice = false;


  }



}
