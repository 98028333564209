import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule  } from 'ngx-ui-loader';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { VoyageComponent } from './pages/voyage/voyage.component';
import { AutomobileComponent } from './pages/automobile/automobile.component';
import { SanteComponent } from './pages/sante/sante.component';
import { HabitationComponent } from './pages/habitation/habitation.component';
import { Page404Component } from './pages/page404/page404.component';
import { TermesEtConditionsComponent } from './pages/termes-et-conditions/termes-et-conditions.component';
import { SpinnerComponent } from './_chargement/spinner/spinner.component';
import { AboutComponent } from './pages/about/about.component';
import { CommentAcheterMonAssuranceComponent } from './pages/comment-acheter-mon-assurance/comment-acheter-mon-assurance.component';
// tslint:disable-next-line:max-line-length
import { RenouvelerModifierMonAssuranceComponent } from './pages/renouveler-modifier-mon-assurance/renouveler-modifier-mon-assurance.component';
// tslint:disable-next-line:max-line-length
import { ComprendreMonContratAssuranceComponent } from './pages/comprendre-mon-contrat-assurance/comprendre-mon-contrat-assurance.component';
import { ContactComponent } from './pages/contact/contact.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatStepperModule, MatIconModule, MatButtonModule, MatFormFieldModule, MatInputModule, MatSliderModule, MatRadioModule } from '@angular/material';
import { LoginComponent } from './pages/login/login.component';
import { AccountComponent } from './pages/account/account.component';
import { PhoneMaskDirective } from './_directives/phone-mask.directive';
import { RegisterComponent } from './pages/register/register.component';
import { TestComponent } from './test/test.component';
import { InscriptionComponent } from './pages/inscription/inscription.component';
import { ConnexionComponent } from './pages/connexion/connexion.component';

import { AuthHeaderInterceptor } from './_shared/auth-header.interceptor';
import { NotificationsComponent } from './pages/notifications/notifications.component';



// Element de traduction
// import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
// import {HttpClientModule, HttpClient} from '@angular/common/http';
// import {TranslateHttpLoader} from '@ngx-translate/http-loader';
// AoT requires an exported function for factories
// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http);
// }

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    VoyageComponent,
    AutomobileComponent,
    SanteComponent,
    HabitationComponent,
    Page404Component,
    TermesEtConditionsComponent,
    SpinnerComponent,
    AboutComponent,
    CommentAcheterMonAssuranceComponent,
    RenouvelerModifierMonAssuranceComponent,
    ComprendreMonContratAssuranceComponent,
    ContactComponent,
    LoginComponent,
    AccountComponent,
    PhoneMaskDirective,
    RegisterComponent,
    TestComponent,
    InscriptionComponent,
    ConnexionComponent,
    NotificationsComponent,

  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxUiLoaderModule,
    NgxUiLoaderRouterModule,
    NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
    DeviceDetectorModule.forRoot(),
    BrowserAnimationsModule,
    MatStepperModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSliderModule,
    MatRadioModule,

    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient]
    //   }
    // })
  ],
  providers: [
    { 
      //provide: LOCALE_ID, useValue: 'fr-FR',
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHeaderInterceptor,
      multi: true
    }
  ],
  
  bootstrap: [AppComponent]
})
export class AppModule { }
