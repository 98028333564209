export const tarifsDieselCatSocieteALLIANZ = [
  {
    libelle: '1',
    prix: 61609
  },
  {
    libelle: '2 à 4',
    prix: 70229
  },
  {
    libelle: '5 à 6',
    prix: 77086
  },
  {
    libelle: '7 à 8',
    prix: 120428
  },
  {
    libelle: '9 et plus',
    prix: 135511
  }
];
