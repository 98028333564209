export const tarifsEssenceCatSocieteSUNU = [
  {
    libelle: '1 à 2',
    prix: 61609
  },
  {
    libelle: '3 à 6',
    prix: 70229
  },
  {
    libelle: '7 à 9',
    prix: 77086
  },
  {
    libelle: '10 à 11',
    prix: 120428
  },
  {
    libelle: '12 et plus',
    prix: 135511
  }
];
