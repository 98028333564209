import { HttpHeaders } from '@angular/common/http';
import { User } from 'src/app/_models/user';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  userAuth: User,
  production: false,
  isAuth: false,
  connectMsgAction: 'Connexion',
  httpOptions: {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      APP_KEY: 'C85Gv9wJaijGRZid20sGbIArm6KYSZmPMB1sVCquTulgh3n2UazTvopGoxJB'
    })
  },
  domaineAPI: 'http://localhost:8000/api/register',
  domaineAuth: 'http://localhost:8000/api/login',
  domaineUpdateMdp: 'http://localhost:8000/api/update',
  domaineUserbyPhone: 'http://localhost:8000/users/phone/',
  domaineUpdateProfile: 'https://localhost/payqin-app/api/public/api/update_infos',
  //domaineTransaction: 'https://app.payqin.com/business-api/transaction',
  domaineTransaction: 'https://business-api-2.ew.r.appspot.com/api/transactions',
  //domaineTransaction: 'https://get-payqin.ew.r.appspot.com/api/business/transaction/view/6066ac3ec01ba10019d38b6e',
};

/*606da70e60506d0019bd4e85 ; 606da76960506d0019bd4e86
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
