import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Tarifs } from '../../_shared/tarifs';
import { MESTARIFS } from '../../_shared/tarifsVoyage';

@Component({
  selector: 'app-habitation',
  templateUrl: './habitation.component.html',
  styleUrls: ['./habitation.component.scss']
})
export class HabitationComponent implements OnInit {

  assurForm: FormGroup;
  isSubmitted = false;
  data: any;
  errorSoumission: boolean;
  resultOffre: any;
  prixOffre = '0';
  showPrix = false;
  private mesTarifs: Tarifs[];

  constructor(private formBuilder: FormBuilder, private router: Router) {

  }

  // convenience getter for easy access to form fields
  get f() { return this.assurForm.controls; }
  get formInvalid() { return this.assurForm.invalid; }

  // choixDateNaissance() {

  //   const val = this.assurForm.value;
  //   // Calcul de l'age du client
  //   const timeDiff = Math.abs(Date.now() - new Date(val.dateNaissance).getTime() );
  //   const ageClient = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
  //   // bebe=[0 - 1 AN] / enfant=[2 - 11 ANS] / adulte=[12 - 80 ANS]
  //   if (ageClient <= 1) {
  //     this.typeClient = 'bebe';
  //   } else if ( 2 <= ageClient && ageClient <= 11) {
  //     this.typeClient = 'enfant';
  //   } else if (12 <= ageClient && ageClient <= 80) {
  //     this.typeClient = 'adulte';
  //   }
  // }

  validationForm() {

    const val = this.assurForm.value;
    this.isSubmitted = true;
    this.resultOffre = '';
    this.prixOffre = '0';
    this.showPrix = false;
    // Formulaire invalid
    if (this.assurForm.invalid)  {
      return;
    } else {
      // Permission show prix
      this.showPrix = true;
    }
    console.log(this.assurForm);

    // Recherche du prix
    this.mesTarifs.forEach(element => {

      if (element.destination === val.destination && element.age === val.age && element.duree === parseInt(val.duree) ) {
        this.resultOffre = element;
        this.prixOffre = this.resultOffre.prix;
      }
    });
    console.log( this.prixOffre );

  }

  ngOnInit() {

    this.assurForm  =  this.formBuilder.group({
      nom: ['', Validators.required],
      prenom: ['', Validators.required],
      email: ['', Validators.email ],
      lieuNaissance: ['', Validators.required],
      destination: ['', Validators.required],
      duree: ['', Validators.required],
      age: ['', Validators.required],
      agree: ['', Validators.required]
    });
    this.errorSoumission = false;
    this.isSubmitted = false;
    this.mesTarifs = MESTARIFS;
    this.showPrix = false;
  }

}
