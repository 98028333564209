import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// import { PhoneMaskDirective } from '../../_directives/phone-mask.directive';
import { LoadingService } from '../../_services/loading.service';
import { ChoixDateValidator } from '../../_validators/chdate.validator';
import { UserService } from '../../_services/user.service';
import { environment } from '../../../environments/environment';
import { User } from '../../_models/user';
import { TokenService } from 'src/app/Services/token.service';

@Component({
  selector: 'app-inscription',
  templateUrl: './inscription.component.html',
  styleUrls: ['./inscription.component.scss']
})

export class InscriptionComponent implements OnInit {

  env = environment;
  createFormGroup: FormGroup;
  isSubmitted = false;
  userCurrent: any;
  isAccountPQBusiExist = true;
  isRegisterSuccess = false;
  isErrorEmail = false;
  isConnected: boolean;
  public error:any = [];

  constructor(private formBuilder: FormBuilder, 
              private loadingService: LoadingService, 
              private userService: UserService,
              private Token: TokenService
              ) { }

  // convenience getter for easy access to form fields
  get f() { return this.createFormGroup.controls; }

  submitFormCreate() {

    this.isSubmitted = true;
    if (this.createFormGroup.invalid)  {
      return;
    } else {

      const phone = '+225' + this.createFormGroup.value.phone.replace(/\s/g, '');
      // Obtention des infos User a partir du numero
      this.userService.getUserInfosByNumber(phone).subscribe(

        (data) => {

          console.log( 'affiche du data' ); console.log( data );
          // console.log( 'affiche du data succes' ); console.log( data['success'] );
          // console.log( 'affiche du data user' ); console.log( data['user'] );
          // console.log( 'affiche du data id' ); console.log( data['user'].id );
          this.isAccountPQBusiExist = true;
          this.userCurrent = new User(
              data['user'].firstName, data['user'].lastName, this.createFormGroup.value.email,
              data['user'].phone, this.createFormGroup.value.birthday, data['user'].id, true
          );
          // console.log( 'affiche userCourrent' ); console.log( this.userCurrent );
          // link register
          this.userService.getRegisterOnApi(this.userCurrent).subscribe(
            (response) => {

              // console.log( 'affiche response succes' ); console.log( response['success'] );
              // console.log( 'affiche response user' ); console.log( response[0].user );
              // console.log( 'affiche userCourent' ); console.log( this.userCurrent );
              if (response['success'] === true) {

                this.isErrorEmail = false;
                this.isRegisterSuccess = true;
              }
              // Email deja existant
              // console.log('contenu du lie response error email'); console.log(response['error'].email);
              if (response['error'].email !== undefined) {
                this.isErrorEmail = true;
              }
            },
            (error) => {
              console.log( error );
            }
          );
        },
        (_erreurNumber) => {

          // console.log('erreur error'); console.log(erreurNumber.error);
          // console.log('erreur status text'); console.log(erreurNumber.error.statusText);
          this.isAccountPQBusiExist = false;
          this.userCurrent = new User(
            this.createFormGroup.value.firstname, 
            this.createFormGroup.value.lastname, 
            this.createFormGroup.value.email,
            this.createFormGroup.value.phone, 
            this.createFormGroup.value.birthday, '', false
          );
          // link register
          this.userService.getRegisterOnApi(this.userCurrent).subscribe(
            (response) => {

              console.log( 'affiche response succes' ); console.log( response['success'] );
              // console.log( 'affiche response user' ); console.log( response[0].user );
              // console.log( 'affiche userCourent' ); console.log( this.userCurrent );
              if (response['success'] === true) {

                this.isErrorEmail = false;
                this.isRegisterSuccess = true;
                // this.env.userAuth.firstname = response[0].user.firstname;
                // this.env.userAuth.lastname = response[0].user.lastname;
                // this.env.userAuth.email = response[0].user.email;
                // this.env.userAuth.phone = response[0].user.phone;
                // this.env.userAuth.birthday = response[0].user.birthday;
                // this.env.userAuth.customerNumber = response[0].user.customerNumber;
                // this.env.userAuth.shopId = response[0].user.shopId;
                // this.env.userAuth.has_payqin_account = response[0].user.has_payqin_account;
                // this.env.userAuth.updated_at = response[0].user.updated_at;
                // this.env.userAuth.created_at = response[0].user.created_at;
                // this.env.userAuth.id = response[0].user.id;
              }


              // Email deja existant
              // console.log('contenu du lien response error email'); console.log(response['error'].email);
              if (response['error'].email !== undefined) {
                this.isErrorEmail = true;
              }
            },

            (data) => { this.handleResponse(data) },
            //(error: any) => this.handleError(error)

            
          );

        }
      );
    }

  }


  handleResponse(data: any) {
    this.Token.handle(data.access_token);
    //this.router.navigateByUrl('/profile');
  }

  handleError(error: { error: { errors: []; }; }) {
    this.error = error.error.errors;
  }

  backOnPageHome() {

    if (this.isConnected){
        window.location.href = '/';
    }
  }

  ngOnInit() {

    this.createFormGroup  =  this.formBuilder.group({
      phone: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      birthday: ['', Validators.compose([Validators.required, ChoixDateValidator.validCdate])],
      email: ['', Validators.email]
    });
    this.isConnected = this.userService.isUserAuth();
    this.backOnPageHome();

  }

}
