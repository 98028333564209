import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenAuthService } from "../_services/services/token-auth.service";

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {

  constructor(private tokenAuthService: TokenAuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const jwtHeaderToken = this.tokenAuthService.getJwtToken();
    request = request.clone({
        setHeaders: {
            Authorization: "Bearer " + jwtHeaderToken
        }
    });

    return next.handle(request);
  }
}
