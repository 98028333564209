export const tarifsEssenceCatSocieteSIDAM = [
  {
    libelle: '1 à 2',
    prix: 58675
  },
  {
    libelle: '3 à 6',
    prix: 66885
  },
  {
    libelle: '7 à 9',
    prix: 73415
  },
  {
    libelle: '10 à 11',
    prix: 114693
  },
  {
    libelle: '12 et plus',
    prix: 129058
  }
];
