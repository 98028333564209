import { Injectable } from '@angular/core';

import { PuissanceTarifs } from '../_shared/auto/models/puissance-tarifs';
import { ChargeUtileTarifs } from '../_shared/auto/models/puissance-tarifs';

// import { ESSENCE } from '../_shared/auto/tarifsEssenceSAHAM';
// import { DIESEL } from '../_shared/auto/tarifsDieselSAHAM';
// import { configOtherConstSAHAM } from '../_shared/auto/configOtherConst';

@Injectable({
  providedIn: 'root'
})

// PROCESSUS CALCUL COUVERTURE TIERS SIMPLE
export class TarifCategorie01Service {

  constructor() { }

  // tslint:disable-next-line:max-line-length
  getResponsabiliteCivileTypePersonnel(puissance: string, carburant: string, tabEssenceCatPerso: Array<PuissanceTarifs>, tabDieselCatPerso: Array<PuissanceTarifs> ): number {

    let resultat = 0;
    // CALCUL AU CAS OU CEST DE LESSENCE
    if (carburant === 'ESSENCE') {
      tabEssenceCatPerso.forEach(element => {
        if (element.libelle === puissance) {
          resultat = element.prix;
          return;
        }
      });
    }
    // CALCUL AU CAS OU CEST DU DIESEL
    if (carburant === 'DIESEL') {
      tabDieselCatPerso.forEach(element => {
        if (element.libelle === puissance) {
          resultat = element.prix;
          return;
        }
      });
    }
    return resultat;
  }
  getResponsabiliteCivileTypeUtilitaire(tabCHARGEUTILE: Array<ChargeUtileTarifs>, chargeUtil: string): number {

    let resultat = 0;
    // CALCUL SELON LA CHARGE UTILE
    tabCHARGEUTILE.forEach(element => {
      if (element.libelle === chargeUtil) {
        resultat = element.prix;
        return;
      }
    });
    return resultat;
  }
  // tslint:disable-next-line:max-line-length
  getResponsabiliteCivileTypeSociete(puissance: string, carburant: string, tabEssenceCatSociete: Array<PuissanceTarifs>, tabDieselCatSociete: Array<PuissanceTarifs> ): number {

    let resultat = 0;
    // CALCUL AU CAS OU CEST DE LESSENCE
    if (carburant === 'ESSENCE') {
      tabEssenceCatSociete.forEach(element => {
        if (element.libelle === puissance) {
          resultat = element.prix;
          return;
        }
      });
    }
    // CALCUL AU CAS OU CEST DU DIESEL
    if (carburant === 'DIESEL') {
      tabDieselCatSociete.forEach(element => {
        if (element.libelle === puissance) {
          resultat = element.prix;
          return;
        }
      });
    }
    return resultat;
  }
  getDefenseRecours(assuranceValConst: any): number {
    return assuranceValConst.DEFENSE_RECOURS;
  }
  getIndividuelConducteur(assuranceValConst: any): number {
    return assuranceValConst.INDIVIDUEL_CONDUCTEUR;
  }
  getIncendie(valVenale: number, assuranceValConst: any): number {
    return (valVenale * assuranceValConst.REDUCE_INCENDIE);
  }
  getVolMainsArmees(valVenale: number, assuranceValConst: any): number {
    return (valVenale * assuranceValConst.REDUCE_VOL_VOL_MAINS_LEVEE);
  }
  getVolVehicule(valVenale: number, assuranceValConst: any): number {
    return (valVenale * assuranceValConst.REDUCE_VOL_VOL_MAINS_LEVEE);
  }
  getVolAccessoire(assuranceValConst: any): number {
    return (assuranceValConst.VOL_ACCESSOIRE);
  }
  getDommageCollisionTiersID(valNeuve: number, assuranceValConst: any): number {
    return (valNeuve * assuranceValConst.REDUCE_TIER_COLLISION_ID);
  }
  getDommageCollisionComplete(valNeuve: number, assuranceValConst: any): number {
    return (valNeuve * assuranceValConst.REDUCE_TIER_COLLISION_COMPLET);
  }
  getBrisDeGlaces(valNeuve: number, assuranceValConst: any): number {
    return (valNeuve * assuranceValConst.REDUCE_BRIS_GLACE);
  }
  getAvanceRecours(assuranceValConst: any): number {
    return assuranceValConst.MTT_AVANCE_RECOURS;
  }
  reduce_respo_civile(profession: string, value: number, assuranceValConst: any): number {
    if (profession === 'religieux' || profession === 'artisan') {
      return ((value * assuranceValConst.REDUCE_PROF_RELIGIEUX) / 100);
    } else if (profession === 'travailleur') {
      return ((value * assuranceValConst.REDUCE_PROF_TRAVAILLEUR) / 100);
    }else {
      return ((value * assuranceValConst.REDUCE_PROF_AUTRE) / 100);
    }
  }

  reduce_commerciale(value: number, assuranceValConst: any): number {
      return (value * assuranceValConst.REDUCE_COMMERCIAL);
  }

  reduce_incendie(value: number, assuranceValConst: any): number {
    return (value * assuranceValConst.REDUCE_COMMERCIAL);
  }
  reduce_vol_mains_armees(value: number, assuranceValConst: any): number {
    return (value * assuranceValConst.REDUCE_COMMERCIAL);
  }
  reduce_bris_glace(value: number, assuranceValConst: any): number {
    return (value * assuranceValConst.REDUCE_COMMERCIAL);
  }
  getPrimeNetTotale(value: number, assuranceValConst: any): number {
    return (value + assuranceValConst.MTT_ACCESSOIRE);
  }
  // TAXE = (P.N+ Mtt_Accessoire) * 0.145
  getTaxe(value: number): number {
    return (value * 0.145);
  }
  // FOD DE GARANTIE = 2% R.C Reduite
  getFondGarantie(rcReduite: number) {
    return ((rcReduite * 2) / 100);
  }
  getMontantCEDEAO(assuranceValConst: any) {
    return assuranceValConst.MTT_CEDEAO;
  }


}
